// DEV SET
const LOCALREACTAPP=true
const LOCALAPI=false
const REACT_APP_NAME='quid3_react'

// REACT APP
export const REACT_APP_ORIGIN=window.location.origin
export const REACT_ROUTER_BASENAME=LOCALREACTAPP?'':`/${REACT_APP_NAME}`
console.log("REACT APP ENVIRONMENT", LOCALREACTAPP?"LOCAL":"DEPLO")
console.log("REACT APP ORIGIN", REACT_APP_ORIGIN)


// API
let api_url = 'http://127.0.0.1:5000/api/v0'
if (!LOCALAPI) { api_url='https://api.turning.rocks/api/v0' } 
export const REACT_APP_API_SERVER_URL=api_url
export const REACT_APP_API_AUDIENCE='https://quid3_api.com'

// Auth0
// quid3 API permissions needed for this frontend
const array_permissions =[
    "profile",   // this is to access user Auth0 profile info
    "get:qandas", "post:qandas", "patch:qandas", "delete:qandas",
    "get:categories", "post:categories", "delete:categories", "get:games", "post:games", "delete:games", 
    "get:players", "post:players", "get:currentplayer", 
    "get:modes", "post:modes", 
    "get:missions", "post:missions", "delete:missions", "get:playermissions", 
    "get:qandarecords", "post:qandarecords", "get:playerqandarecords",
    "get:gamerecords", "post:gamerecords", "get:playergamerecords",
    "get:missionrecords", "post:missionrecords", "get:playermissionrecords", 
    "get:gamestats", "post:gamestats"
].join(" ")  // joins all the elements of the list separated with a space

// console.log("array_permissions", array_permissions)


export const REACT_APP_AUTH0_DOMAIN='quid3.eu.auth0.com'
export const REACT_APP_AUTH0_CLIENT_ID='sbBjTrKO3hGLfnsMEAE9KLKlbhTg44eg'
export const REACT_APP_AUTH0_SCOPE=array_permissions





