import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllGames, getGamesStatus, getGamesError, selectGame, deleteGame } from "./gamesSlice";
import { getAllQandas } from "../qandas/qandasSlice";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import TableRWD from "../../components/TableRWD"


const GamesTable = () => {
    const [selected_category_id, selected_mode_id, selected_player_id, playerQandaRecords, playerGameRecords, playerMissionRecords] = useOutletContext()

    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const games = useSelector(getAllGames)
    const status = useSelector(getGamesStatus)
    const errors = useSelector(getGamesError)
    const qandas = useSelector(getAllQandas)
    const [dataList, setDataList] = useState(null)

    useEffect(() => {

        console.log("games", games)

        console.log("selected_mode_id", selected_mode_id)
        const games_filtered = games.filter((g) => (selected_mode_id > -1 && g.game_mode_id == selected_mode_id) || selected_mode_id == -1)

        const game_records = playerGameRecords ? playerGameRecords : []

        let data = []
        for (let i=0; i<games_filtered.length; i++) {
            const game = games_filtered[i]

            // const nb_qandas = game.qanda_ids.length

            const qanda_ids = game.qanda_ids.map((i) => qandas.find((q) => q.id == i)?.id )

            const records = game_records.filter((r) => r.game_id === game.id)

            const record_bar = (records) => {
                if (records.length > 0) {
                    return (
                        <div key={i} className="flex flex-row justify-left items-center">
                            {records.map((r,ri) => (
                                <div key={ri} className={`text-xs text-center w-5 hover:bg-white ${r.score==1 && r.time ? 'bg-green-500' : 'bg-red-500'}`}> {r.score==1 && r.time ? r.time : "-"} </div>
                            ))}
                        </div>
                    )
                } else {
                    return <div></div>
                }
            }
    
            const row = {
                "id": game.id,
                "name": game.name,
                "game_mode_id": game.game_mode_id,
                "extra_qandas": game.extra_qandas,
                "qanda_ids": qanda_ids.toString(),
                // "nb_qandas": nb_qandas,
                "bar": record_bar(records),
                "selected": game.selected
            }
            data.push(row)
        }
        setDataList(data)

    }, [selected_mode_id, selected_player_id, playerGameRecords, games, qandas])

    const handleSelectRow = (e) => {
        console.log("select checkbox, e", e)
        dispatch(selectGame(e.target.id))
    }

    const handleDeleteRow = (e) => { 
        const delete_args = {
            "getAccessTokenSilently": getAccessTokenSilently,
            "id": e.target.id
        }
        dispatch(deleteGame(delete_args))
    }

    return (        
        <TableRWD headList={["Name", "qandas", "extra", "mode", "ID", "player records"]} dataList={dataList} dataKeysList={["name", "qanda_ids", "extra_qandas", "game_mode_id", "id", "bar"]} status={status} handleSelectRow={handleSelectRow} handleDeleteRow={handleDeleteRow}/>
    )
}

export default GamesTable



