import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

const initialState = { 
    missions: [],
    status: 'idle',
    error: null
}

export const fetchPlayerMissions = createAsyncThunk('missions/fetchPlayerMissions', async (args) => {
    const requestType = "get"
    const endpoint = "/missions/player"
    return await payloadCreator(requestType, args, endpoint)
})


const playerMissionsSlice = createSlice({
    name: "playerMissions", // ensure this name is in 'store'
    initialState,
    reducers: {
        // selectMission: {
        //     reducer(state, action){
        //         state.games = state.games.map((q) => (q.id==action.payload)? {...q, "selected": !q.selected } : q )
        //     },
        // }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPlayerMissions.pending, (state, action) => {
                // console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchPlayerMissions.fulfilled, (state, action) => {
                // payload.games = list if all game objects
                state.status = 'succeeded'
                state.missions = action.payload && action.payload.missions ? action.payload.missions.map(m => {
                    return {
                        "id": m.id,
                        "name": m.name,
                        "player_id": m.player_id,
                        "target_score": m.target_score,
                        "target_times_played": m.target_times_played,
                        "target_date": m.target_date,
                        "game_ids": m.game_ids
                    }
                }) : []
            })
            .addCase(fetchPlayerMissions.rejected, (state, action) => {
                console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getPlayerMissions = (state) => state.playerMissions.missions
export const getPlayerMissionsStatus = (state) => state.playerMissions.status
export const getPlayerMissionsError = (state) => state.playerMissions.error

// export const { selectGame } = gamesSlice.actions

export default playerMissionsSlice.reducer

