import { Outlet } from 'react-router-dom';
import RecordsNavBar from './RecordsNavBar';


export default function PlayerRecords() {

    return (        
        <div>
            <RecordsNavBar />
            <Outlet />
        </div>
    )
}