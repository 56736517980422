import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// import { REACT_APP_API_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_API_SERVER_URL } from "../../config"
// import axios from "axios";

// const API_AUDIENCE = REACT_APP_API_AUDIENCE;
// const AUTH_SCOPE = REACT_APP_AUTH0_SCOPE;
// const API_URL = REACT_APP_API_SERVER_URL;

const initialState = { 
    games: [],
    status: 'idle',
    error: null
}

export const fetchGames = createAsyncThunk('games/fetchGames', async (args) => {
    const requestType = "get"
    const endpoint = "/games/"
    return await payloadCreator(requestType, args, endpoint)
})

export const postGame = createAsyncThunk('games/postGame', async (args) => {
    const requestType = "post"
    const endpoint = "/games/"
    return await payloadCreator(requestType, args, endpoint)
})

export const deleteGame = createAsyncThunk('games/deleteGame', async (args) => {
    const requestType = "delete"
    const endpoint = "/games/"
    return await payloadCreator(requestType, args, endpoint)
})





const gamesSlice = createSlice({
    name: "games", // ensure this name is in 'store'
    initialState,
    reducers: {
        // qandasAdded: {
        //     reducer(state, action){
        //         // push would normally mutate the array BUT not in createSlice. 
        //         // createSlice is using immerjs, which mutates the state underneath
        //         state.qandas.push(action.payload)
        //     },
        //     // prepare callback for the postAdded reducer function, that prepares the 
        //     // payload for the reducer
        //     prepare(question, answer, note, category_id) {
        //         return {
        //             payload: { id: nanoid(), question, answer, note, category_id }
        //         }
        //     }
        // }
        selectGame: {
            reducer(state, action){
                state.games = state.games.map((q) => (q.id==action.payload)? {...q, "selected": !q.selected } : q )
            },
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchGames.pending, (state, action) => {
                // console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchGames.fulfilled, (state, action) => {
                // payload.games = list if all game objects                     
                // console.log("state.games", state.games)   
                state.status = 'succeeded'
                state.games = action.payload && action.payload.games ? action.payload.games.map(g => {
                    return {
                        "id": g.id,
                        "name": g.name,
                        "game_mode_id": g.game_mode_id,
                        "qanda_ids": g.qanda_ids,
                        "extra_qandas": g.extra_qandas,
                        "selected": false
                    }
                }) : []
            })
            .addCase(fetchGames.rejected, (state, action) => {
                console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(postGame.fulfilled, (state, action) => {
                // payload.game = game object created                       
                // console.log("action.payload", action.payload)
                // console.log("state.games", state.games)           
                if (action.payload && action.payload.game) {
                    const new_game = action.payload.game
                    state.games.push({
                        "id": new_game.id,
                        "name": new_game.name,
                        "game_mode_id": new_game.game_mode_id,
                        "qanda_ids": new_game.qanda_ids,
                        "extra_qandas": new_game.extra_qandas
                    })
                }
            })
            .addCase(deleteGame.fulfilled, (state, action) => {
                // payload.game = game object deleted                
                console.log("action.payload", action.payload)
                if (action.payload && action.payload.game) {
                    state.games = state.games.filter((q) => q.id !== action.payload.game.id)
                }
            })
    }
})

export const getAllGames = (state) => state.games.games
export const getGamesStatus = (state) => state.games.status
export const getGamesError = (state) => state.games.error

export const { selectGame } = gamesSlice.actions

export default gamesSlice.reducer

