import React, { useState, useEffect } from "react";
import { getAllGames, getGamesStatus, getGamesError } from "./gamesSlice";

import { getPlayerGameRecords } from "../records/playerGameRecordsSlice";
import { useSelector, useDispatch } from "react-redux";
import TableR from "../../components/TableR"



const PlayerGamesTable = () => {
    const games = useSelector(getAllGames)
    const status = useSelector(getGamesStatus)
    const errors = useSelector(getGamesError)
    const currentPlayerGameRecords = useSelector(getPlayerGameRecords)
    const [dataList, setDataList] = useState(null)

    useEffect(() => {

        const game_records = currentPlayerGameRecords ? currentPlayerGameRecords : []

        let data = []
        for (let i=0; i<games.length; i++) {
            const game = games[i]
            const records = game_records.filter((r) => r.game_id === game.id)
    
            if (records.length > 0) {
                const record_bar = (records) => {
                    return (
                        <div key={i} className="flex flex-row justify-left items-center">
                            {records.map((r,ri) => (
                                <div key={ri} className={`text-xs text-center w-5 hover:bg-white ${r.score === 1 ? 'bg-green-500' : 'bg-red-500'}`}> {r.score == 1 ? r.time : "-"} </div>
                            ))}
                        </div>
                    )
                }
    
                const row = {
                    "id": game.id,
                    "name": game.name,
                    "game_mode_id": game.game_mode_id,
                    "bar": record_bar(records)
                }
                data.push(row)
            }

            setDataList(data)
        }
        
    }, [currentPlayerGameRecords])

    return (        
        <TableR headList={["id", "Name", "mode", "records"]} dataList={dataList} dataKeysList={["id", "name", "game_mode_id", "bar"]} status={status} />
    )
}

export default PlayerGamesTable



