import { createAsyncThunk, createSlice, nanoid, current } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// playerQandaRecords
// - GET qanda records for the current player
// - POST records at completion of games


const initialState = {   
    records: [],
    status: 'idle',
    error: null
}

export const fetchPlayerQandaRecords = createAsyncThunk('records/fetchPlayerQandaRecords', async (args) => {
    const requestType = "get"
    const endpoint = "/qanda_records/player"
    return await payloadCreator(requestType, args, endpoint)
})

export const postQandaRecords = createAsyncThunk('records/postQandaRecords', async (args) => {
    const requestType = "post"
    const endpoint = "/qanda_records/"
    return await payloadCreator(requestType, args, endpoint)
})



const playerQandaRecordsSlice = createSlice({
name: "playerQandaRecords", // ensure this name is in 'store'
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPlayerQandaRecords.fulfilled, (state, action) => {
                // payload.records = list if all record objects
                // console.log("fetchPlayerQandaRecords, action.payload", action.payload)
                state.status = 'succeeded'
                state.records = action.payload && action.payload.records ? action.payload.records.map(record => {
                    return {...record};
                }) : []
            })        
            .addCase(postQandaRecords.fulfilled, (state, action) => {
                // payload.record = record object created
                // console.log("postQandaRecord, action.payload", action.payload)

                if (action.payload && action.payload.records_added) {
                    const new_records_added = action.payload.records_added
                    const records_dropped = action.payload.records_dropped
                    const records_dropped_ids = records_dropped ? records_dropped.map((r) => r.id) : []

                    const new_records = state.records.concat(new_records_added)
                    const new_records_trimmed = new_records.filter((r) => !records_dropped_ids.includes(r.id) )

                    state.records = new_records_trimmed
                }
            })
    }
})


export const getPlayerQandaRecords = (state) => state.playerQandaRecords.records
export const getQandaRecordsStatus = (state) => state.playerQandaRecords.status
export const getQandaRecordsError = (state) => state.playerQandaRecords.error

// export const { loadCurrentPlayerQandaRecords } = qandaRecordsSlice.actions

export default playerQandaRecordsSlice.reducer

