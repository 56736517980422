import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavBar from './AdminNavBar';
import { fetchAllPlayers, getAllPlayers } from '../features/players/allPlayersSlice';
import { getAllCategories } from '../features/categories/categoriesSlice';
import { getAllGameModes } from '../features/modes/gameModesSlice';
import { fetchAllMissions, getAllMissions } from '../features/missions/allMissionsSlice';
import { fetchAllQandaRecords, getAllQandaRecords } from '../features/records/allQandaRecordsSlice';
import { fetchAllGameRecords, getAllGameRecords } from '../features/records/allGameRecordsSlice';
import { fetchAllMissionRecords, getAllMissionRecords } from '../features/records/allMissionRecordsSlice';
import NewGameModeForm from '../features/modes/NewGameModeForm';
import NewCategoryForm from '../features/categories/NewCategoryForm';
import NewQandaForm from '../features/qandas/NewQandaForm';
import NewGameForm from '../features/games/NewGameForm';
import NewMissionForm from '../features/missions/NewMissionForm';
import ImportCategory from './ImportCategory';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import DropdownList from "../components/DropdownList"



export default function Admin() {

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const allPlayers = useSelector(getAllPlayers)
    const [players, setPlayers] = useState([])
    const [selected_player_id, setSelected_player_id] = useState(-1)

    const allCategories = useSelector(getAllCategories)
    const [categories, setCategories] = useState([])
    const [selected_category_id, setSelected_category_id] = useState(-1)

    const allGameModes = useSelector(getAllGameModes)
    const [modes, setModes] = useState([])
    const [selected_mode_id, setSelected_mode_id] = useState(-1)

    const allMissions = useSelector(getAllMissions)

    const allQandaRecords = useSelector(getAllQandaRecords)
    const allGameRecords = useSelector(getAllGameRecords)
    const allMissionRecords = useSelector(getAllMissionRecords)

    const [playerQandaRecords, setPlayerQandaRecords] = useState([])
    const [playerGameRecords, setPlayerGameRecords] = useState([])
    const [playerMissionRecords, setPlayerMissionRecords] = useState([])

    const [hideForms, setHideForms] = useState(true)
    const [hideImports, setHideImports] = useState(true)

    useEffect(() => {  // load all players and all records

        if (!allPlayers) {
            dispatch( fetchAllPlayers({getAccessTokenSilently}) )
        }
        if (!allMissions) {
            // console.log("allMissions", allMissions)
            dispatch( fetchAllMissions({getAccessTokenSilently}) )
        }
        if (!allQandaRecords) {
            dispatch( fetchAllQandaRecords({getAccessTokenSilently}) )
        }
        if (!allGameRecords) {
            dispatch( fetchAllGameRecords({getAccessTokenSilently}) )
        }
        if (!allMissionRecords) {
            dispatch( fetchAllMissionRecords({getAccessTokenSilently}) )
        }   
        if (allPlayers && allQandaRecords && allGameRecords && allMissionRecords) {  // if all loaded, set list players and selected_player_id
            setPlayers([{"name": "no player selected", "id": -1}, ...allPlayers])
            setSelected_player_id(-1)
            const modeList = allGameModes.map((m) =>  ({"id": m.id, "label": "mode " + m.id}))
            setModes([{"label": "all modes", "id": "-1"}, ...modeList])
            setSelected_mode_id(-1)
            setCategories([{"name": "all categories", "id": -1}, ...allCategories])
            setSelected_category_id(-1)
        }

    }, [allPlayers, allQandaRecords, allGameRecords, allMissionRecords])    


    useEffect(() => {  // once selected_player_id set (ie all players and records loaded as well), filter records for the selected player

        if (selected_player_id > -1 && allQandaRecords && allGameRecords) {
            setPlayerQandaRecords(allQandaRecords.filter((r) => r.player_id == selected_player_id))
            setPlayerGameRecords(allGameRecords.filter((r) => r.player_id == selected_player_id))
            setPlayerMissionRecords(allMissionRecords.filter((r) => r.player_id == selected_player_id))
        }
        if (selected_player_id == -1) {
            setPlayerQandaRecords([])
            setPlayerGameRecords([])
            setPlayerMissionRecords([])
        }      

    }, [selected_player_id])


    const handleHideFormsClick = (e) => {
        setHideForms((hideForms) => !hideForms)
    }
    const handleHideImportsClick = (e) => {
        setHideImports((hideImports) => !hideImports)
    }

    return (
        <div className="">
            <div className="flex justify-between items-center">
                <AdminNavBar />

                <button onClick={handleHideFormsClick} className="w-[80px] border-solid border-black border-1 bg-gray-500 hover:bg-gray-700 text-white text-xs py-2 h-8 rounded-sm mx-2">
                    {hideForms ? "FORMS" : "HIDE FORMS"}
                </button>

                <button onClick={handleHideImportsClick} className="w-[80px] border-solid border-black border-1 bg-gray-500 hover:bg-gray-700 text-white text-xs py-2 h-8 rounded-sm mx-2">
                    {hideImports ? "IMPORTS" : "HIDE IMPORTS"}
                </button>

                <div className="mr-2 text-sm">
                    <DropdownList items={categories} itemDisplayField="name" itemId={selected_category_id} setItemId={setSelected_category_id}/>
                    <DropdownList items={modes} itemDisplayField="label" itemId={selected_mode_id} setItemId={setSelected_mode_id}/>
                    <DropdownList items={players} itemDisplayField="name" itemId={selected_player_id} setItemId={setSelected_player_id}/>
                </div>
            </div>

            <div hidden={hideForms}>
                <NewGameModeForm />
                <NewCategoryForm />
                <NewQandaForm />
                <NewGameForm />
                <NewMissionForm selected_player_id={selected_player_id}/>
            </div>

            <div hidden={hideImports}>
                <ImportCategory />
            </div>

            <Outlet context={[selected_category_id, selected_mode_id, selected_player_id, playerQandaRecords, playerGameRecords, playerMissionRecords]}/> 

        </div>
    )
}

