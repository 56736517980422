import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { getAllGames } from "../features/games/gamesSlice"
import { getPlayer } from "../features/players/playerSlice"
import { getAllGameStats } from "../features/stats/gamesStatsSlice"
import { getPlayerGameRecords } from "../features/records/playerGameRecordsSlice"


export default function Training2() {

    const navigate = useNavigate();
    const games = useSelector(getAllGames)
    const gameRecords = useSelector(getPlayerGameRecords)
    const [gamesInfo, setGamesInfo] = useState(null)

    useEffect(() => {

        if (games && gameRecords) {

            const games_info = games.map((g) => {

                const records = gameRecords.filter((r) => r.game_id == g.id)
                const game_status = records.reduce((p,c) => {
                    return {
                        "times_played": c.times_played > p.times_played ? c.times_played : p.times_played, 
                        "max_score": p.max_score ? (c.score > p.max_score ? c.score : p.max_score) : c.score, 
                        "min_time": p.min_time ? (c.time < p.min_time ? c.time : p.min_time) : c.time
                    }
                }, {"times_played": 0, "max_score": null, "min_time": null})
        
                const record_bar = (recs) => {
                    return (
                        <div className="flex flex-row justify-left items-center">
                            {recs.map((r,ri) => (
                                <div key={ri} className={`text-xs text-center w-1 hover:bg-white ${r.score === 1 ? 'bg-green-500' : 'bg-red-500'}`}> . </div>
                            ))}
                        </div>
                    )
                }
        
                return {
                    "id": g.id,
                    "name": g.name,
                    "times_played": game_status.times_played,
                    "best_score": Math.round(game_status.max_score * 100) / 100,
                    "best_time": game_status.min_time,
                    "score_record_bar": record_bar(records)
                }
            })
            setGamesInfo(games_info)
        }

    }, [games, gameRecords])


    useEffect(()=>{
    }, [gamesInfo])

    const handleClick = (e) => {
        const selectedGame = games[e.currentTarget.id]
        // navigate("/training/game/" + selectedGame.id, { state : selectedGame })
        console.log("/training/game/" + selectedGame.id)
        navigate("/training/game/" + selectedGame.id)
    }

    return (
        <div className="bg-yellow-200 h-screen py-4">

            <div className="flex flex-wrap justify-center gap-3">
            {gamesInfo && gamesInfo.map((game, i) => (
                <button
                        key={i}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mx-2" 
                        type="button"
                        id={i}
                        onClick={handleClick}
                    >
                        <div className="flex flex-col">
                            <h2 className="font-bold">{game.name}</h2>
                            <span className="text-xs">played x {game.times_played}</span>
                            <span className="text-xs">best score {game.best_score}</span>
                            <span className="text-xs">{game.best_time ? "best time " + game.best_time + "s" : "-"}</span>
                            {game.score_record_bar}
                        </div>
                </button>        
            ))}
            </div>




        </div>
    )
}