import { useSelector, useDispatch } from 'react-redux';
import { authState } from '../features/auth/authSlice'
import Back4 from './Back4.js'



export default function Home() {


    const { isAuthenticated, isLoading, user } = useSelector(authState)

    let textcontent = "haricot"

    let container = ""
    let sizemaker = ""
    let card = ""
    let cardcontent = ""
    let cardfront = ""
    let cardback = ""



    // WORKING:
    // container = "flex justify-center items-center border-solid border-black border-2 h-60 w-60"
    // card = "border-solid border-green-400 border-2 [perspective:1000px] h-50 w-50 bg-transparent group"
    // cardcontent = "relative [transform-style:preserve-3d] transition-all duration-500 h-full w-full group-hover:[transform:rotateY(180deg)]"
    // cardfront = "absolute [backface-visibility:hidden] h-full w-full"





    container = "flex justify-center items-center border-solid border-black border-2 h-min w-min"
    sizemaker = "absolute -z-10 invisible"
    // // card = "border-solid border-blue border-2 m-1 [perspective:1000px] hover:[transform:rotateY(180deg)] h-50 w-50 bg-transparent"
    card = "border-solid border-green-400 border-2 [perspective:1000px] bg-transparent group"
    // // cardcontent = "relative transition-all duration-500 [transform-style:preserve-3d] [transform:rotateY(180deg)] text-center h-full w-full"
    cardcontent = "relative [transform-style:preserve-3d] transition-all duration-1 group-hover:[transform:rotateY(180deg)]"
    cardfront = " [backface-visibility:hidden] "
    // cardback = "absolute [backface-visibility:hidden] h-full w-full [transform:rotateY(180deg)]"







    return (
        <div className="flex justify-center bg-yellow-200 h-screen py-4">

            <img className="size-20" src={user.picture} alt={user.nickname} />
            

            {/* <div className={container ?? ""}>
                <div className={sizemaker}> {textcontent} </div>
                
                <div className={card ?? ""}>
                    <div className={cardcontent ?? ""}>
                        <div className={cardfront ?? ""}>
                            <div className="bg-blue-300 rounded h-full w-full p-6"> {textcontent} </div>
                        </div>
                        <div className={cardback ?? ""}>
                            <div className="bg-amber-300 rounded h-full w-full p-6">  </div>
                        </div>
                    </div>

                </div>
            </div> */}



        </div>
    )
}