import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";

import LoginButton from "./features/auth/LoginButton";
import AppNavBar from './routing/AppNavBar';
import Home from './routing/Home';
import Training from './routing/Training';
import TrainingGame from './routing/TrainingGame';
import Mission from './routing/Mission';
import PlayerMissions from './routing/PlayerMissions';
import PlayerRecords from './routing/PlayerRecords';
// import PlayerQandas from './routing/PlayerQandas';
// import PlayerGames from "./routing/PlayerGames";
import PlayerQandasTable from "./features/qandas/PlayerQandasTable"
import PlayerGamesTable from "./features/games/PlayerGamesTable"

import Admin from './routing/Admin';
import QandasTable from "./features/qandas/QandasTable"
import GameModesTable from "./features/modes/GameModesTable"
import CategoriesTable from "./features/categories/CategoriesTable"
import GamesTable from "./features/games/GamesTable"
import MissionsTable from "./features/missions/MissionsTable"

import NoMatch from './routing/NoMatch';

import { REACT_ROUTER_BASENAME } from "./config"

import useAuthListener from './features/auth/useAuthListener'
import { authState } from './features/auth/authSlice'

import { fetchGameModes } from "./features/modes/gameModesSlice";
import { fetchCategories } from "./features/categories/categoriesSlice";
import { fetchQandas } from "./features/qandas/qandasSlice";
import { fetchGames } from "./features/games/gamesSlice"; 

import { fetchPlayerMissions } from "./features/missions/playerMissionsSlice";
import { fetchPlayerQandaRecords } from "./features/records/playerQandaRecordsSlice"; 
import { fetchPlayerGameRecords } from "./features/records/playerGameRecordsSlice"; 
import { fetchPlayerMissionRecords } from "./features/records/playerMissionRecordsSlice"; 

import { postNewPlayer, fetchPlayer, getPlayer } from './features/players/playerSlice'


// TODO: SIGNUP + create new player record at signup
// for the moment, the app assumes the user has already a profile in the db


function App() {
  const auth = useAuthListener()   // Listening if Auth state has changed
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated, isLoading, user } = useSelector(authState)

  const currentPlayer = useSelector(getPlayer)
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  // ----- create new player record in db   ------ 
    // const post_args = {
    //   "getAccessTokenSilently": getAccessTokenSilently,
    //   "post_request_body": {"name": user.nickname, "sub": user.sub, "admin": true}
    // }
    // dispatch(postNewPlayer(post_args)).unwrap()



  // fetch current player
  useEffect(() => {
    // https://react.dev/reference/react/useEffect#fetching-data-with-effects

    if (isAuthenticated && user) {

      const { sub } = user

      console.log("load player", sub)
  
      if (sub) {
        const fetchPlayer_args = {
          "getAccessTokenSilently": getAccessTokenSilently,
          "get_request_params": { "sub": sub }
        }
        dispatch( fetchPlayer(fetchPlayer_args)).unwrap()
        setPlayerLoaded(true)
      }
    }
    
  }, [isAuthenticated, getAccessTokenSilently, user])


  // if current player loaded => fetch other data
  useEffect(() => {

    if (currentPlayer && playerLoaded && isAuthenticated && user) {

      // fetch non-player specific data
      dispatch( fetchQandas({getAccessTokenSilently}) )
      dispatch( fetchGameModes({getAccessTokenSilently}) )
      dispatch( fetchCategories({getAccessTokenSilently}) )
      dispatch( fetchGames({getAccessTokenSilently}) )

      // fetch player specific data
      const fetchPlayerRecords_args = {
        "getAccessTokenSilently": getAccessTokenSilently,
        "get_request_params": { "player_id": currentPlayer.id }
      }
      dispatch( fetchPlayerMissions(fetchPlayerRecords_args) )
      dispatch( fetchPlayerQandaRecords(fetchPlayerRecords_args) )
      dispatch( fetchPlayerGameRecords(fetchPlayerRecords_args) )
      dispatch( fetchPlayerMissionRecords(fetchPlayerRecords_args) )

      setIsAdmin(currentPlayer.admin)

    }

  }, [currentPlayer])



  // this however holds 
  // if (isLoading) {
  //   // return <Loading />;
  //   return <div>loading...</div>    
  // }

  return (
    <div className="App">

      {isAuthenticated ? ( 
        <BrowserRouter basename={REACT_ROUTER_BASENAME}>

          <AppNavBar />

          <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/missions" element={<PlayerMissions />} />
                <Route path="/missions/mission/:id" element={<Mission />} />

                <Route path="/training" element={<Training />} />
                <Route path="/training/game/:id" element={<TrainingGame />} />

                <Route path="/records" element={<PlayerRecords />} >
                        <Route path="qandas" element={<PlayerQandasTable />} />
                        <Route path="games" element={<PlayerGamesTable />} />
                        <Route path="*" element={<NoMatch />} />
                </Route>

                {isAdmin &&  
                  <Route path="/admin" element={<Admin />}>
                        <Route path="allqandas" element={<QandasTable />} />
                        <Route path="allgamemodes" element={<GameModesTable />} />
                        <Route path="allcategories" element={<CategoriesTable />} />
                        <Route path="allgames" element={<GamesTable />} />
                        <Route path="allmissions" element={<MissionsTable />} />                        
                        <Route path="*" element={<NoMatch />} />
                  </Route>
                }
                <Route path="*" element={<NoMatch />} />
          </Routes>
        
        </BrowserRouter>
      ) : (
        <div className="flex flex-row justify-center align-middle items-center">            
          <LoginButton />
        </div>
      )}

    </div>
  );
}

export default App;


// NOTES::
// <Route path=  ** omit the '/' for relative paths
// nested routes under /admin:
//    associated element will be rendered where a <Outlet> is included (as long as it is included within the element of the parent route, ie <Admin>)(I)
