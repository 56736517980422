import { NavLink, useLocation } from "react-router-dom";


export default function AdminNavBar() {

    const setLinkActiveStyle = (isActive) => {
        return {
          fontWeight: isActive ? "700" : "",
          color: isActive ? "blue" : "black",
        };
    }

    return (          
        <div className="py-3 mx-3">

            <div className="flex flex-row justify-between align-middle items-center">

                {/* <nav variant="pills" className="navbar" style={{ borderBottom: "1px solid white" }}> */}
                <nav variant="pills" className="navbar">
                    <ul className="nav nav-pills flex flex-row gap-3 text-sm">
                        <li>
                            <NavLink style={({ isActive }) => setLinkActiveStyle(isActive)} to="allgamemodes">MODES</NavLink>
                        </li>                        
                        <li>
                            <NavLink style={({ isActive }) => setLinkActiveStyle(isActive)} to="allcategories">CATEGORIES</NavLink>
                        </li>
                        <li>
                            <NavLink style={({ isActive }) => setLinkActiveStyle(isActive)} to="allqandas">QANDAS</NavLink>
                        </li>                        
                        <li>
                            <NavLink style={({ isActive }) => setLinkActiveStyle(isActive)} to="allgames">GAMES</NavLink>
                        </li>                            
                        <li>
                            <NavLink style={({ isActive }) => setLinkActiveStyle(isActive)} to="allmissions">MISSIONS</NavLink>
                        </li>                 
                    </ul>

                </nav>

                {/* <div>
                        select player
                </div> */}

            </div>
        </div>
    )
}


