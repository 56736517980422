import { createAsyncThunk, createSlice, nanoid, current } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// allQandaRecords
// - for admin use only
// - monitoring all players' records to setup missions/games
// - only GET request required (POST record is handled by individual players during play)



const initialState = {   
    records: null,
    status: 'idle',
    error: null
}

export const fetchAllQandaRecords = createAsyncThunk('records/fetchAllQandaRecords', async (args) => {
    const requestType = "get"
    const endpoint = "/qanda_records/all"
    return await payloadCreator(requestType, args, endpoint)
})


const allQandaRecordsSlice = createSlice({
    name: "allQandaRecords", // ensure this name is in 'store'
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder 
            .addCase(fetchAllQandaRecords.pending, (state, action) => {
                // console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchAllQandaRecords.fulfilled, (state, action) => {
                // payload.records = list if all record objects                     
                state.status = 'succeeded'
                state.records = action.payload && action.payload.records ? action.payload.records.map(record => {
                    return {...record};
                }) : []
            })
            .addCase(fetchAllQandaRecords.rejected, (state, action) => {
                // console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getAllQandaRecords = (state) => state.allQandaRecords.records
export const getAllQandaRecordsStatus = (state) => state.allQandaRecords.status
export const getAllQandaRecordsError = (state) => state.allQandaRecords.error


export default allQandaRecordsSlice.reducer

