import { createAsyncThunk, createSlice, nanoid, current } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// allPlayers
// - for admin use only
// - monitoring all players to setup missions/games etc
// - only GET request required (POST is handled only at new signup - see playerSlice)

const initialState = { 
    players: null,
    status: 'idle',
    error: null
}

export const fetchAllPlayers = createAsyncThunk('players/fetchAllPlayers', async (args) => {
    const requestType = "get"
    const endpoint = "/players/all"
    return await payloadCreator(requestType, args, endpoint)
})


const allPlayersSlice = createSlice({
    name: "allPlayers",
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllPlayers.fulfilled, (state, action) => {
                // payload.players = list if all player objects                    
                // console.log("fetchAllPlayers", action.payload)
                state.status = 'succeeded'
                state.players = action.payload && action.payload.players ? action.payload.players : []
            })
    }
})


export const getAllPlayers = (state) => state.allPlayers.players
export const getPlayerStatus = (state) => state.allPlayers.status
export const getPlayerError = (state) => state.allPlayers.error

// export const { setCurrentPlayer } = playersSlice.actions

export default allPlayersSlice.reducer

