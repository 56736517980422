



export function shuffle(array) {

    let new_array = array

    let currentIndex = new_array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [new_array[currentIndex], new_array[randomIndex]] = [
        new_array[randomIndex], new_array[currentIndex]];
    }
    return new_array
  }

// Used like so
// let arr = [2, 11, 37, 42];
// shuffle(arr);
// console.log(arr);


export const sortArrayByDateAscending = (array) => { return array.sort(function(a,b){
    return new Date(a.datetime) - new Date(b.datetime)
  })
};