import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getAllGames } from "../features/games/gamesSlice"
import Game from "./Game";


export default function TrainingGame() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [gameStatus, setGameStatus] = useState({
        "notStarted": true,
        "inProgress": false,
        "finished": false
    })   

    const allGames = useSelector(getAllGames)
    const game = allGames.find((g) => g.id == id)
    const [gameResult, setGameResult] = useState(null) // 

    useEffect(() => {  // to avoid crashing if refresh with mission or missionGames undefined (ie refresh of /mission/id)
        if (!game) { navigate("/training") }
      }, [game])

    useEffect(() => {
        console.log("gameStatus", gameStatus)
    }, [gameStatus])


    return (

        <div className="flex flex-col space-y-10 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-amber-500">

            <Game
                gameStatus={gameStatus}
                setGameStatus={setGameStatus}
                game={game}
                gameResult={gameResult}
                setGameResult={setGameResult}
                callBackUrl={"/training"}
            />

        </div>

    )
}




