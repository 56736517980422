import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// allMissionRecords
// - for admin use only
// - monitoring all players' records
// - only GET request required (POST record is handled by individual players during play)



const initialState = { 
    records: null,
    status: 'idle',
    error: null
}

export const fetchAllMissionRecords = createAsyncThunk('records/fetchAllMissionRecords', async (args) => {
    const requestType = "get"
    const endpoint = "/mission_records/all"
    return await payloadCreator(requestType, args, endpoint)
})


const allMissionRecordsSlice = createSlice({
    name: "allMissionRecords", // ensure this name is in 'store'
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllMissionRecords.pending, (state, action) => {
                // console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchAllMissionRecords.fulfilled, (state, action) => {
                // payload.records = list if all record objects                  
                // console.log("fetchAllMissionRecords, action.payload", action.payload)
                state.status = 'succeeded'
                state.records = action.payload && action.payload.records ? action.payload.records.map(record => {
                    return {...record};
                }) : []
            })
            .addCase(fetchAllMissionRecords.rejected, (state, action) => {
                // console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getAllMissionRecords = (state) => state.allMissionRecords.records
export const getAllMissionRecordsStatus = (state) => state.allMissionRecords.status
export const getAllMissionRecordsError = (state) => state.allMissionRecords.error

// export const { selectQanda } = qandasSlice.actions

export default allMissionRecordsSlice.reducer

