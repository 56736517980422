import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// allGameRecords
// - for admin use only
// - monitoring all players' records to setup missions/games
// - only GET request required (POST record is handled by individual players during play)



const initialState = { 
    records: null,
    status: 'idle',
    error: null
}

export const fetchAllGameRecords = createAsyncThunk('records/fetchAllGameRecords', async (args) => {
    const requestType = "get"
    const endpoint = "/game_records/all"
    return await payloadCreator(requestType, args, endpoint)
})


const allGameRecordsSlice = createSlice({
    name: "allGameRecords", // ensure this name is in 'store'
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllGameRecords.pending, (state, action) => {
                // console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchAllGameRecords.fulfilled, (state, action) => {
                // payload.records = list if all record objects                  
                // console.log("fetchAllGameRecords, action.payload", action.payload)
                state.status = 'succeeded'
                state.records = action.payload && action.payload.records ? action.payload.records.map(record => {
                    return {...record};
                }) : []
            })
            .addCase(fetchAllGameRecords.rejected, (state, action) => {
                // console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const getAllGameRecords = (state) => state.allGameRecords.records
export const getAllGameRecordsStatus = (state) => state.allGameRecords.status
export const getAllGameRecordsError = (state) => state.allGameRecords.error

// export const { selectQanda } = qandasSlice.actions

export default allGameRecordsSlice.reducer

