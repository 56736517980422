import React, { useState, useEffect } from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from 'react-redux';
import { getAllGames } from '../games/gamesSlice'
import { DateTime, toLocaleString } from "luxon"
import Datepicker from "react-tailwindcss-datepicker"; 

import { postMission } from '../missions/allMissionsSlice'



const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-row items-center gap-4">
      <label className="text-sm w-[100px] ml-2" htmlFor={props.id || props.name}>{label}</label>
      <input className="text-sm text-input text-center min-w-[300px]" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="text-sm error">{meta.error}</div>
      ) : null}
    </div>
  );
};



// And now we can use these
const NewMissionForm = ({selected_player_id}) => {

    const dispatch = useDispatch()
    const { getAccessTokenSilently } = useAuth0();
    const games = useSelector(getAllGames)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const [datePickerDate, setDatePickerDate] = useState({ // DatePicker uses {startDate, endDate} format
        startDate: new Date(),
        endDate: new Date()
    }) 
    const [targetDate, setTargetDate] = useState(DateTime.now())

    const selectedGameIds = games.filter((g) => g.selected == true).map((g)=>g.id) //TODO: this does not allow to control the order of the games in the mission. To be updated

    const numberSelectedGames = selectedGameIds.length


    const handleDatePickerDateChange = (newValue) => {
        // console.log("newValue:", newValue);

        if (newValue && newValue.startDate) {
            const newDate = newValue.startDate

            setDatePickerDate({
                startDate: new Date(newDate),
                endDate: new Date(newDate)                
            }) 
            setTargetDate(
                DateTime.fromFormat(newDate, "yyyy-MM-dd")
            ) 
        } else {
            setDatePickerDate({
                startDate: null,
                endDate: null                
            }) 
            setTargetDate(null) 


        }
    } 


    // useEffect(() => {
    //     console.log("datePickerDate:", datePickerDate);
    //     console.log("targetDate:", targetDate);

    // }, [datePickerDate, targetDate])

    const handleSubmit = (values) => {

        const body = {
            ...values, 
            "player_id": Number(selected_player_id),
            "game_ids": selectedGameIds,
            "target_date": targetDate
        }
        // console.log("handleSubmit body", body)

        if (selected_player_id > -1 && selectedGameIds.length > 0 && targetDate) {
            try {
                setAddRequestStatus('pending')
                const post_args = {
                    "getAccessTokenSilently": getAccessTokenSilently,
                    "post_request_body": body
                }
                dispatch(postMission(post_args)).unwrap()
            } catch (err) {
                console.error('Failed to save the game', err)
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }


  return (
    <div className="gap-3 mx-3 items-center">
        <div className="w-[250px]">
            <h1 className="font-bold bg-amber-300">New Mission</h1>
        </div>
      
      <Formik
        initialValues={{
          name: '',
          target_score: 1,
          target_times_played: 1,
          target_date: DateTime.now()
        }}
        validationSchema={Yup.object({
            name: Yup.string()
                .max(200, 'Must be 20 characters or less')
                .required('Required'),
            target_score: Yup.number("Must be number")
                // .required("Required")
                .positive()
                .integer()
                .min(0, "Min is 0")
                .max(1, "max is 1"),
            target_times_played: Yup.number("Must be number")
                // .required("Required")
                .positive()
                .integer()
                .min(1, "Min is 1")
                .max(100, "max is 100"),
            target_date: Yup.date()  //TODO: to be reviewed
                // .required("Required")
        })}
        onSubmit={handleSubmit}
      >

        <Form className="border-solid border-2 border-blue pt-1">

            <MyTextInput
                label="name"
                name="name"
                type="text"
                placeholder="mission"
            />
            <MyTextInput
                label="score"
                name="target_score"
                type="number"
                placeholder="1"
            />
            <MyTextInput
                label="times"
                name="target_times_played"
                type="number"
                placeholder="target"
            />

            <div className="flex items-center">
                <div className="text-sm w-[150px] pl-2 pr-5">target date</div>
                <div className="w-[150px]">
                    <Datepicker 
                        inputClassName="text-sm align-middle"
                        asSingle={true} 
                        value={datePickerDate} 
                        onChange={handleDatePickerDateChange} 
                        // placeholder={targetDate.startDate}
                        displayFormat={"DD / MM / YYYY"}
                    /> 
                </div>

            </div>

            <div className="flex flex-row items-center gap-4 pb-1">
                <div className="text-sm w-[150px] ml-2">games selected</div>
                <div className="text-sm text-input text-center min-w-[160px]"> {numberSelectedGames} </div>
            </div>

            <button type="submit" className="text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-0 px-20 rounded ml-3  mt-1 mb-4">Create</button>
        </Form>
      </Formik>
    </div>
  );
};

export default NewMissionForm;

