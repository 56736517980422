import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllGameModes, getGameModesStatus, getGameModesError, selectGameMode, deleteGameMode } from "./gameModesSlice";
import { useSelector, useDispatch } from "react-redux";

import TableRWD from "../../components/TableRWD"


const GameModesTable = () => {
    const modes = useSelector(getAllGameModes)
    const status = useSelector(getGameModesStatus)
    const errors = useSelector(getGameModesError)

    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const dataList = modes
    console.log("modes", modes)

    const handleSelectRow = (e) => {
        console.log("select checkbox, e", e)
        dispatch(selectGameMode(e.target.id))
    }

    const handleDeleteRow = (e) => {
        // --- qanda mode delete disabled ---
        // const delete_args = {
        //     "getAccessTokenSilently": getAccessTokenSilently,
        //     "id": e.target.id
        // }
        // dispatch(deleteQandaMode(delete_args))
    }


    return (        
        <TableRWD headList={["Mode", "id"]} dataList={dataList} dataKeysList={["name", "id"]} status={status} handleSelectRow={handleSelectRow} handleDeleteRow={handleDeleteRow} />
    )
}

export default GameModesTable

