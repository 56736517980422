import React, { useState, useEffect, useRef } from "react";
import GameStartModal from "./GameStartModal"
import GameFinishModal from "./GameFinishModal"
import GameClock from "./GameClock"
import { getAllQandas } from "../features/qandas/qandasSlice";
import { getPlayer } from "../features/players/playerSlice";
import { postGameStat } from "../features/stats/gamesStatsSlice";
import { postQandaRecords } from "../features/records/playerQandaRecordsSlice";
import { postGameRecord } from "../features/records/playerGameRecordsSlice";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { shuffle } from "../utils/utils"
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime, toLocaleString } from "luxon"


export default function Game( {gameStatus, setGameStatus, game, gameResult, setGameResult, callBackUrl=null} ) {

    const dispatch = useDispatch()
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const qandas = useSelector(getAllQandas)
    const player = useSelector(getPlayer)

    const [currentInd, setCurrentInd] = useState(0) // index in the sequence of questions to be displayed

    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [note, setNote] = useState("")
    const [gameModeId, setGameModeId] = useState(null)

    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [notes, setNotes] = useState([])
    const [qandaIds, setQandaIds] = useState([])

    const [options, setOptions] = useState([])
    const [selectedOptionsArray, setSelectedOptionsArray] = useState([])    
    const [selectedAnswer, setSelectedAnswer] = useState("")

    // const [nextWordOrder, setNextWordOrder] = useState(0)

    const [gameTime, setGameTime] = useState(0)
    const [questionTime, setQuestionTime] = useState(0)

    // const [questionTime0, setQuestionTime0] = useState(0) // time at start of question
    const [attempt, setAttempt] = useState(1)
    const [answered, setAnswered] = useState("not")
    const [nbCorrectAnswers, setNbCorrectAnswers] = useState(0)

    const [qandaRecord, setQandaRecord] = useState([])

    // useEffect(() => {  // to avoid crashing if refresh with mission or missionGames undefined (ie refresh of /mission/id)

    //     if (!gameStatus && !game) {
    //       navigate("/training")
    //     }
    //   }, [game, gameStatus])


    const setQandasForGame = (game, qandas) => {
        // shuffle the ids
        const ids = [...game.qanda_ids]
        const shuffled_ids = shuffle(ids)
        const qandasForGame = shuffled_ids.map((game_qid) => qandas.find((q) => q.id == game_qid))

        // set list of questions (ignore extra questions)
        const extra_questions = game.extra_qandas
        const allquestions = qandasForGame.map((q) => q.question)
        const questions = allquestions.filter((q,i) => (i < allquestions.length - extra_questions))

        // set list of answers (include extra answers as required by extra_qandas)
        const answers = qandasForGame.map((q) => q.answer)
        const notes = qandasForGame.map((q) => q.note)
        const qanda_ids = qandasForGame.map((q) => q.id)
        // const qanda_modes = qandasForGame.map((q) => q.mode_id)

        setQuestions(questions)
        setAnswers(answers)
        setOptions(setAnswersOptions(answers, game.game_mode_id))
        setNotes(notes)
        setQandaIds(qanda_ids)
        // setQandaModes(qanda_modes)

        // set the first question
        setQuestion(questions[0] ?? "")
        setAnswer(answers[0] ?? "")
        setNote(notes[0] ?? "")
        // setQandaMode(qanda_modes[0] ?? "")
    }


 

    const setAnswersOptions = (answers, mode) => { // L1: full answers / L2: words / L3: letters

        let optionUnits = []
        for (let i=0; i<answers.length; i++) {
            const option = answers[i]

            if (mode==3) {
                optionUnits = optionUnits.concat(option.split(''))
            } else if (mode==2) {
                optionUnits = optionUnits.concat(option.split(/(?<=\s)/))   // split(/(?<=\s)/)  //  split(' ')
            } else {
                optionUnits = optionUnits.concat([option])
            }
        }

        console.log("mode", mode)
        console.log("answers", answers)
        console.log("optionUnits", optionUnits)

        const inds = [...Array(optionUnits.length).keys()]
        const shuffled_inds = shuffle(inds)
        const options = shuffled_inds.map((ind, i) => (
            {"optionInd": i,
            "optionUnit": optionUnits[ind],
            "selected": false}
        ))  

        console.log("options", options)
        return options
    }

    const setQuestionAndOptionsForInd = (ind) => {
        if (ind < questions.length) {
            setQuestion(questions[ind] ?? "")
            setAnswer(answers[ind] ?? "")
            setNote(notes[ind] ?? "")
            // setQandaMode(qandaModes[ind] ?? null) 
        } else { // no more questions
            setQuestion("")
            setSelectedOptionsArray([])
            setSelectedAnswer("")
            setNote("")
            // setQandaMode(null) 
            setOptions([])
            setGameStatus({"notStarted": false, "inProgress": false, "finished": true})
        }
    }

    const showAnswerResponse = (message) => {
        setAnswered(message)
        setTimeout(() => {
            setAnswered("not")
            setSelectedOptionsArray([])            
            setSelectedAnswer("")
        }, 400);
    }


    // const makeSentenceFromWords = (wordsArray) => {
    //     let sentence = ""
    //     for (let i=0; i<wordsArray.length; i++) {
    //         sentence = i>0 ? sentence + " " + wordsArray[i] : wordsArray[i]
    //     }
    //     return sentence
    // }

    const handleOptionClicked = (e) => {

        const selectedOption = options[e.target.id]
        const newSelectedOptionsArray = [...selectedOptionsArray, selectedOption]
        setSelectedOptionsArray(newSelectedOptionsArray)
        const newSelectedAnswer = newSelectedOptionsArray.reduce((p,c) => p + c.optionUnit, "")   // p==""? c.word : p + " " + c.word, "")  //TODO: to adjust for modes
        console.log("newSelectedAnswer", newSelectedAnswer)
        setSelectedAnswer(newSelectedAnswer)
        setOptions(options.map((o,i) => (
            i == e.target.id? {...o, "selected": true} : {...o}
        )))
    }

    const handleClearLastClicked = (e) => {

        if (selectedOptionsArray.length > 0) {

            console.log("selectedOptionsArray", selectedOptionsArray)
            console.log("selectedOptionsArray.length", selectedOptionsArray.length)

            const lastSelectedOption = selectedOptionsArray[selectedOptionsArray.length-1]

            console.log("lastSelectedOption", lastSelectedOption)

            setOptions(options.map((o,i) => (
                i == lastSelectedOption.optionInd? {...o, "selected": false} : {...o}
            )))

            const newSelectedOptionsArray = selectedOptionsArray.slice(0, selectedOptionsArray.length-1)

            console.log("newSelectedOptionsArray", newSelectedOptionsArray)

            setSelectedOptionsArray(newSelectedOptionsArray)
            const newSelectedAnswer = newSelectedOptionsArray.reduce((p,c) => p==""? c.optionUnit : p + " " + c.optionUnit, "")   //TODO: to adjust for modes
            console.log("newSelectedAnswer", newSelectedAnswer)
            setSelectedAnswer(newSelectedAnswer)
        }
    }

    const handleClearAllClicked = (e) => {
        if (selectedOptionsArray.length > 0) {
            setOptions(options.map((o,i) => ( {...o, "selected": false} )))
            setSelectedOptionsArray([])
            setSelectedAnswer("")
        }
    }    

    const handleSubmitAnswer = (e) => { 

        if (selectedAnswer == answer) {
            showAnswerResponse('correctly')

            setQandaRecord([...qandaRecord, {
                "qanda_id": qandaIds[currentInd],
                "correct": attempt===1? true : false,
                "attempts": attempt,
                "time": questionTime, // gameTime - questionTime0,
                "game_mode_id": gameModeId
            }])

            // setQuestionTime0(gameTime)
            setNbCorrectAnswers((nbCorrectAnswers) => attempt === 1? nbCorrectAnswers + 1 : nbCorrectAnswers)
            setAttempt(1)

            setQuestionAndOptionsForInd(currentInd + 1)
            setCurrentInd(currentInd + 1)
            setQuestionTime(0)
        } else {
            showAnswerResponse('wrongly')
            setAttempt((attempt) => attempt+1)
        }
        setOptions(options.map((o,i) => ({...o, "selected": false})))
    }

    useEffect(() => {    // RE-INITIALISE WHEN NEW GAME
        // - set questions/options arrays for the new game
        // - reset index for questions/options to 0
        // - reset timer to 0
        // - reset number of correct answers to 0
        // - reset qandaRecords to empty

        setQandasForGame(game, qandas)
        setGameModeId(game.game_mode_id)
        setCurrentInd(0)
        setGameTime(0)
        setQuestionTime(0)
        setNbCorrectAnswers(0)
        setQandaRecord([])
    }, [game])

    // useRef to access the intervalId outside useEffect (or from another useEffect)
    const gameTimerIntervalIdRef = useRef()
    const questionTimerIntervalIdRef = useRef()

    useEffect(() => {
        if (gameStatus.inProgress) {   // START THE TIMER
            gameTimerIntervalIdRef.current = setInterval(() => {
                setGameTime((gameTime) => gameTime + 1)
            }, 1000)
            questionTimerIntervalIdRef.current = setInterval(() => {
                setQuestionTime((questionTime) => questionTime + 1)
            }, 1000)
        }

        if (gameStatus.finished) {   // WHEN GAME FINISHED
            // - stop the timer
            // - post gameRecords
            // - post qandaRecords
            // - update state gameResult

            clearInterval(gameTimerIntervalIdRef.current)
            clearInterval(questionTimerIntervalIdRef.current)

            const postGameStat_args = {    //TODO: remove stats
                "getAccessTokenSilently": getAccessTokenSilently,
                "post_request_body": {
                    "time": gameTime,
                    "player_id": player.id,
                    "game_id": game.id
                }
            }
            console.log("gameTime", gameTime)
            console.log("player.id", player.id)
            console.log("game.id", game.id)                        
            dispatch(postGameStat(postGameStat_args)).unwrap()



            // console.log("questions", questions)
            // console.log("nbCorrectAnswers", nbCorrectAnswers)

            const score = questions.length > 0 ? nbCorrectAnswers / questions.length : 0
            const postGameRecords_args = {
                "getAccessTokenSilently": getAccessTokenSilently,
                "post_request_body": {
                    "player_id": player.id,
                    "game_id": game.id,
                    "nb_questions": questions.length,
                    "score": score,
                    "time": score > 0.999 ? gameTime : null,
                    "datetime": DateTime.now()
                }
            }
            console.log("postGameRecords_args", postGameRecords_args)

            dispatch(postGameRecord(postGameRecords_args)).unwrap()
            
            const postQandaRecord_args = {
                "getAccessTokenSilently": getAccessTokenSilently,
                "post_request_body": {
                    "player_id": player.id,
                    "qanda_results": qandaRecord,
                    "datetime": DateTime.now()
                }
            }
            dispatch(postQandaRecords(postQandaRecord_args)).unwrap()

            setGameResult({
                "nb_questions": questions.length,
                "nb_correct_answers": nbCorrectAnswers,
                "time": score > 0.999 ? gameTime : null
            })
            // console.log("setGameResult, score", score)
            // console.log("setGameResult, time", time)
        }

    }, [gameStatus])


    // useEffect(() => {

    // }, [gameResult])

    // useEffect(() => {

    // }, [gameResult])

    // <div className="flex flex-row items-center space-x-32"> <GameClock time={time}/> </div>

    return (
        <div className="flex flex-col space-y-10 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-200">

            {gameStatus.notStarted &&
                <GameStartModal game={game} gameStatus={gameStatus} setGameStatus={setGameStatus} />
            }

            {gameStatus.inProgress &&
                <>
                <div className="flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none gap-5">

                    <div className="w=[360px] flex flex-row items-center space-x-3">
                        <div className="flex flex-col items-center">
                            <div className="text-[20px]"> QUESTION</div>
                            <div className="text-[20px]"> {currentInd + 1} of {questions.length} </div>
                        </div>
                        
                        <div className="flex flex-row items-center space-x-32"> <GameClock time={gameTime}/> </div>

                        <div className="flex flex-col items-center">
                            <div className="text-[20px]"> CORRECT</div>
                            <div className="text-[20px]"> {nbCorrectAnswers}  </div>
                        </div>

                    </div>

                    <div className="flex flex-col justify-center items-center align-items">
                        <div className="w-[360px] min-h-[40px] border-solid border-2 border-black px-14 rounded-xl text-[22px] text-center font-bold bg-white"> {question} </div>
                        <div className="text-[12px] italic"> {note? "(" + note + ")" : "-"} </div>
                    </div>

                    <div className={`w-[360px] h-[38px] border-solid border-black border-2 pb-3 px-8 rounded-xl text-[22px] text-center ${answered === 'not' ? 'bg-yellow-500 text-white' : answered === 'correctly'? 'bg-green-500 text-white' : 'bg-rose-600 text-white'}`}>
                        {selectedAnswer}                 
                    </div>
      
                    <div className="w-[360px] flex flex-row flex-wrap gap-1 border-solid border-black border-2 rounded-xl items-center p-2 bg-white">
                        {options.map((option, i) => (
                            <div key={i} id={i} className={`flex-auto border-solid border-black border-2 p-4 text-center text-[18px] rounded-xl bg-gray-600 hover:bg-yellow-500 ${option.selected? "text-black" : "text-white"} `} onClick={handleOptionClicked}> {option.optionUnit} </div>
                        ))}
                    </div>


                    <div className="w-[360px] flex justify-center items-center align-items gap-4">
                        <button
                            className="flex-auto border-solid border-black border-2 bg-orange-300 hover:bg-orange-700 text-white h-8 rounded-xl"
                            type="button"
                            onClick={handleClearLastClicked}
                        >
                            clear last
                        </button>
                        <button
                            className="flex-auto border-solid border-black border-2 bg-red-300 hover:bg-red-700 text-white h-8 rounded-xl"
                            type="button"
                            onClick={handleClearAllClicked}
                        >
                            clear all
                        </button>
                    </div>



                    

                    <div className="flex p-1 border-t border-solid border-blueGray-200 rounded-b gap-20">
                        <button
                            className="w-[360px] border-solid border-black border-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 h-20 rounded-xl mx-2"
                            type="button"
                            onClick={handleSubmitAnswer}
                        >
                            submit
                        </button>

                    </div>

                </div>
                </>
            }

            {gameStatus.finished &&
                <GameFinishModal gameStatus={gameStatus} setGameStatus={setGameStatus} gameResult={gameResult} callBackUrl={callBackUrl}/>
            }

        </div>
    )
}





