import { createAsyncThunk, createSlice, nanoid, current } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// player
// - GET current player at login
// - POST player at signup

const initialState = { 
    player: null,    
    status: 'idle',
    error: null
}


export const fetchPlayer = createAsyncThunk('player/fetchPlayer', async (args) => {
    const requestType = "get"
    const endpoint = "/players/current"
    return await payloadCreator(requestType, args, endpoint)
})

export const postNewPlayer = createAsyncThunk('player/postNewPlayer', async (args) => {
    const requestType = "post"
    const endpoint = "/players/new"
    return await payloadCreator(requestType, args, endpoint)
})




const playerSlice = createSlice({
    name: "player",  // ensure this name is in 'store'
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPlayer.fulfilled, (state, action) => {
                // payload.currentPlayer = current player object                   
                // console.log("fetchPlayer", action.payload)
                state.status = 'succeeded'
                state.player = action.payload && action.payload.player ? {
                    "id": action.payload.player.id, 
                    "name": action.payload.player.name,
                    "sub": action.payload.player.sub,
                    "admin": action.payload.player.admin
                } : {}

            })
            .addCase(postNewPlayer.fulfilled, (state, action) => {
                // payload.player = player object created                
                // console.log("action.payload", action.payload)
                // if (action.payload && action.payload.player) {
                //     state.allPlayers.push(action.payload.player)
                // }
            })
    }
})



export const getPlayer = (state) => state.player.player
export const getPlayerStatus = (state) => state.player.status
export const getPlayerError = (state) => state.player.error

// export const { setCurrentPlayer } = playerSlice.actions

export default playerSlice.reducer

