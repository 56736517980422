import React, { useState, useEffect } from "react";
// import { deleteQanda } from "../features/qandas/qandasSlice";
// import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux';
// import { selectQanda } from '../features/qandas/qandasSlice'

const TableRWD = ( { headList, dataList, dataKeysList, status, handleSelectRow, handleDeleteRow } ) => {

    // adjust the number of columns for the select checkboxes and the delete buttons if any
    headList = handleSelectRow? ["", ...headList] : headList
    headList = handleDeleteRow? [...headList, ""] : headList

    useEffect(() => {
        // console.log("dataList", dataList)
    }, [dataList])


    return (

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        {headList ? (
                            headList.map((head, i) => (
                                <th key={i} scope="col" className="px-6 py-2"> {head} </th>
                            ))
                        ) : (
                            null
                        )}
                    </tr>
                </thead>

                {/* TABLE BODY ROWS */}
                <tbody>
                    {dataList && status === "succeeded" ? (
                        dataList.map((data, i) => (
                        
                            <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
  
                                {handleSelectRow &&
                                    <td className="pl-6 w-8">
                                        <input type="checkbox" id={data.id} name="selection" value="selection" onChange={handleSelectRow} checked={data.selected} />
                                    </td>
                                }

                                {dataKeysList.map((key,i) => (
                                    <td key={i} className="px-6 py-2"> 
                                        {data[key]} 
                                        
                                    </td>
                                ))}

                                {handleDeleteRow &&
                                    <td key={i} className="font-medium text-blue-600 dark:text-blue-500 hover:font-bold" onClick={handleDeleteRow} id={data.id}>
                                        delete
                                    </td>  
                                }

                            </tr>
                            ))
                        ) : (
                           <tr><th>No data</th></tr>
                        )
                    }
                </tbody>

            </table>
        </div>
    )
}

export default TableRWD

