import { createAsyncThunk, createSlice, nanoid, current } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

const initialState = { 
    stats: [],
    status: 'idle',
    error: null
}


export const fetchGameStats = createAsyncThunk('game_stats/fetchGameStats', async (args) => {
    const requestType = "get"
    const endpoint = "/game_stats/"
    return await payloadCreator(requestType, args, endpoint)
})

export const postGameStat = createAsyncThunk('game_stats/postGameStat', async (args) => {
    const requestType = "post"
    const endpoint = "/game_stats/"
    return await payloadCreator(requestType, args, endpoint)
})

const gameStatsSlice = createSlice({
    name: "gameStats",
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            // .addCase(fetchQandas.pending, (state, action) => {
            //     console.log('loading')
            //     state.status = 'loading'
            // })
            .addCase(fetchGameStats.fulfilled, (state, action) => {
                // payload.stats = list if all stat objects                    
                state.status = 'succeeded'
                state.stats = action.payload && action.payload.stats ? action.payload.stats.map(stat => {
                    return {...stat};
                }) : []
            })
            .addCase(fetchGameStats.rejected, (state, action) => {
                // console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(postGameStat.fulfilled, (state, action) => {
                // payload.stat = stat object created                       
                // console.log("postStat, action.payload", action.payload)
                if (action.payload && action.payload.stat) {
                    const new_stat = action.payload.stat
                    const existing_stat = state.stats.find((s) => (s.player_id == new_stat.player_id) && (s.game_id == new_stat.game_id))

                    let updated_stats = [...state.stats]
                    if (existing_stat && existing_stat.best_time > 0) {
                        if (new_stat.best_time > 0 && (new_stat.best_time < existing_stat.best_time)) {
                            updated_stats = state.stats.map((s) => {
                                if (s.id==existing_stat.id) {
                                    return {...s,
                                        "best_time": new_stat.best_time,
                                        "date_time": new_stat.date_time,
                                        "times_played": existing_stat.times_played + 1 }
                                } else {
                                    return {...s}
                                }
                            })
                        } else {
                            updated_stats = state.stats.map((s) => {
                                if (s.id==existing_stat.id) {
                                    return {...s,
                                        "times_played": existing_stat.times_played + 1 }
                                } else {
                                    return {...s}
                                }
                            })
                        }

                        state.stats = updated_stats

                    } else {
                        state.stats.push(new_stat)
                    }
                }
            })
            // .addCase(deleteQanda.fulfilled, (state, action) => {
            //     console.log("action.payload", action.payload)
            //     if (action.payload && action.payload[0].id) {
            //         state.qandas = state.qandas.filter((q) => q.id !== action.payload[0].id)
            //     }
            // })
    }
})

export const getAllGameStats = (state) => state.gameStats.stats
export const getGameStatsStatus = (state) => state.gameStats.status
export const getGameStatsError = (state) => state.gameStats.error

// export const { selectQanda } = gameStatsSlice.actions

export default gameStatsSlice.reducer

