import { useState, useEffect, useCallback } from 'react';
import { read, utils, writeFile } from 'xlsx';
import TableRWD from '../components/TableRWD'
import { postQanda } from '../features/qandas/qandasSlice' 
import { postCategory } from '../features/categories/categoriesSlice'
import { postNewPlayer } from '../features/players/playerSlice'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux';


const ALLOWEDFILETYPE = ["application/vnd.ms-excel"]


export default function ImportCategory() {

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()

    const [selectedFile, setSelectedFile] = useState(null);
    const [allData, setAllData] = useState(null)
    const [dataList, setDataList] = useState([]);
    const [seeDataName, setSeeDataName] = useState(null)
    const [error, setError] = useState("");
    const [status, setStatus] = useState("nofileselected")  // "nofileselected" / "fileselected" / "fileloaded"

    // const dataNames = ["qanda_categories", "qandas", "game_modes", "games", "players"]  // worksheets to have these exact names
    const dataNames = ["qanda_categories", "qandas", "players"]  // worksheets to have these exact names
    const headLists = {
        "qanda_categories": ["category_name"],
        "qandas": ["category_id", "question", "answer", "note"],
        "game_modes": ["name"],
        "games": ["name", "qanda_ids", "extra", "mode"],
        "players": ["name", "sub", "admin"]
    }
    const dataKeysLists = {
        "qanda_categories": ["name"],
        "qandas": ["category_id", "question", "answer", "note"],
        "game_modes": ["name"],
        "games": ["name", "qanda_ids", "extra", "mode"],
        "players": ["name", "sub", "admin"]
    }


    const handleFileChange = (e) => {
        console.log("onFileChange, e", e)
        setError("")

        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            const fileType = inputFile?.type;
            if ( !ALLOWEDFILETYPE.includes(fileType) ) {
                setError("Please input a xls file");
                return;
            }

        setSelectedFile(e.target.files[0]);
        setStatus("fileselected")
        }
    };


    const handleFileUpload = async (e) => {
        // Uploads the xls workbook (wb) as a dictionary:
        // - keys : are the worksheet (ws) names
        // - values : are the worksheet (ws) content
        // for each worksheet value, an array of rows (dictionaries with keys as the column names)

        // TODO:
        // - postprocessing of xls values
        //      - True/False in xls are TRUE/FALSE, transform to 'True'/'False'
        // 
        console.log("e", e);

        if (selectedFile) {

            // Details of the uploaded file
            console.log("selectedFile", selectedFile);

            const ab = await selectedFile.arrayBuffer();
            console.log("ab", ab);

            /* parse */
            const wb = read(ab);

            const wsNames = wb.SheetNames?? []
            console.log("wsNames", wsNames);

            const wbData = wsNames.reduce((pdict,cname) => {
                let ws = wb.Sheets[cname]
                pdict[cname] = utils.sheet_to_json(ws)
                return pdict
            }, {})

            console.log("wbData", wbData);

            setAllData(wbData); // update state
            setStatus("fileloaded")
        }
    };


    const handleSeeData = (e) => {
        console.log("e", e);
        setSeeDataName(() => e.target.id)
    }



    const handlePostData = async (e) => {

        // post categories
        // - OK direct: no dependence
        try {

            console.log('seeDataName', seeDataName)

            const dataListToPost = allData[seeDataName]

            console.log('dataListToPost', dataListToPost)

            let post_args = {
                "getAccessTokenSilently": getAccessTokenSilently,
                "post_request_body": null
            }

            for (let i=0; i<dataListToPost.length; i++) {

                post_args["post_request_body"] = dataListToPost[i]
                
                switch(seeDataName) {
                    case "qanda_categories":
                        dispatch(postCategory(post_args)).unwrap()
                        break;
                    case "qandas":
                        dispatch(postQanda(post_args)).unwrap()
                        break;
                    case "players":
                        dispatch(postNewPlayer(post_args)).unwrap()
                        break;
                    default:
                }

            }


        } catch (err) {
            console.error('Failed to post categories', err)
        } finally {
            // setAddRequestStatus('idle')
        }

    }

    const handleSelectRow = (e) => {
    }

    const handleDeleteRow = (e) => { 
    }

    
    useEffect(() => {
        console.log(status)

        if (allData && seeDataName) {
            console.log("seeDataName", seeDataName)

            console.log("headLists[seeDataName]", headLists[seeDataName])
            console.log("dataKeysLists[seeDataName]", dataKeysLists[seeDataName])

            console.log("allData[seeDataName]", allData[seeDataName])
        }

    }, [status, allData, seeDataName])


    return (
        <div className='mb-8 mt-8'>  
            <div className='ml-3'>
            
                <label htmlFor={"fileInput"} className="text-sm align-items">Choose the xls workbook: </label>
                <input
                    type="file"
                    id="fileInput"
                    accept=".csv, .xls, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={handleFileChange}
                    className="text-sm"
                />

                {status=="fileselected" &&
                    <button onClick={handleFileUpload} id="btn-categories" className='w-[100px] border-solid border-black border-1 bg-gray-500 hover:bg-gray-700 text-white text-xs py-1 h-6 rounded-sm mx-2'>
                        Upload file
                    </button>
                }

                {status=="fileloaded" && dataNames.map((name, i) => (
                    <button key={i} onClick={handleSeeData} id={name} className='w-[120px] border-solid border-black border-1 bg-gray-500 hover:bg-gray-700 text-white text-xs py-1 h-6 rounded-sm mx-2'>
                        see {name}
                    </button>
                ))}
            </div>

            <div className='mt-3'>
            {seeDataName && allData[seeDataName] && 

                <div className='text-sm'>
                    <TableRWD headList={headLists[seeDataName]} dataList={allData[seeDataName]} dataKeysList={dataKeysLists[seeDataName]} status="succeeded" handleSelectRow={null} handleDeleteRow={null}/>
                    <button onClick={handlePostData} id={seeDataName} className='w-[180px] border-solid border-black border-1 bg-gray-500 hover:bg-gray-700 text-white text-xs py-1 h-6 rounded-sm mx-2'>
                        Post data
                    </button>
                </div>
            }
            </div>

           
        </div>
    )


}






    /* Fetch and update the state once */
    // useEffect(() => { (async() => {
    //     /* Download from https://docs.sheetjs.com/pres.numbers */
    //     const f = await fetch("https://docs.sheetjs.com/pres.numbers");
    //     const ab = await f.arrayBuffer();
    
    //     /* parse */
    //     const wb = read(ab);
    
    //     /* generate array of objects from first worksheet */
    //     const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    //     const data = utils.sheet_to_json(ws); // generate objects
    
    //     /* update state */
    //     setPres(data); // update state
    // })(); }, []);








        // // Create an object of formData
        // const formData = new FormData();
 
        // // Update the formData object
        // formData.append(
        //     "myFile",   // field name
        //     selectedFile,   // field value
        //     selectedFile.name   // file name
        // );


            // console.log("formData", formData);
            // console.log("formData.getAll(myFile)", formData.getAll("myFile"))
            // Request made to the backend api
            // Send formData object
            // axios.post("api/uploadfile", formData);



        
            // // Initialize a reader which allows user to read any file or blob.
            // const reader = new FileReader();
        
            // // Event listener on reader when the file loads, we parse it and set the data.
            // reader.onload = async ({ target }) => {
            //     console.log(target.result);
            // };
            // reader.readAsText(selectedFile);



