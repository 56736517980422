import React, { useState, useEffect, useRef } from "react";
import MissionStartModal from "./MissionStartModal"
import MissionFinishModal from "./MissionFinishModal"

import { postMissionRecord } from "../features/records/playerMissionRecordsSlice";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime, toLocaleString } from "luxon"

import { getPlayerMissions } from "../features/missions/playerMissionsSlice"
import { getAllGames } from "../features/games/gamesSlice"
import { getPlayer } from "../features/players/playerSlice"
import Game from "./Game";


export default function Mission() {

    const dispatch = useDispatch()
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const { id } = useParams();

    const [missionStatus, setMissionStatus] = useState({
        "notStarted": true,
        "inProgress": false,
        "finished": false
    })   
    const [currentGameStatus, setCurrentGameStatus] = useState({
        "notStarted": true,
        "inProgress": false,
        "finished": false
    }) 

    const missions = useSelector(getPlayerMissions)
    const allGames = useSelector(getAllGames)
    const player = useSelector(getPlayer)

    const mission = missions.find((m) => m.id == id)
    const missionGameIds = mission? mission.game_ids : []
    const missionGames = missionGameIds.map((id) => allGames.find((g) => g.id == id))

    console.log("missionGameIds", missionGameIds)
    console.log("missionGames", missionGames)

    const [currentMissionGameInd, setCurrentMissionGameInd] = useState(0)
    const [missionResult, setMissionResult] = useState(null)

    console.log("currentMissionGameInd", currentMissionGameInd)

    const [currentGame, setCurrentGame] = useState(missionGames.length > 0 ? missionGames[currentMissionGameInd] : null)
    const [currentGameResult, setCurrentGameResult] = useState(null)
    const [gameResults, setGameResults] = useState([])

    useEffect(() => {  // to avoid crashing if refresh with mission or missionGames undefined (ie refresh of /mission/id)

        if (!mission || !missionGames) {
          navigate("/missions")
        }
      }, [mission, missionGames])

    useEffect(() => {
        // when currentGameStatus.finished => set the incremented gameInd
        // when currentGameStatus.notStarted => set the new game or set mission.finished

        if (currentGameStatus.finished && currentGameResult) {
            // add game result to array
            setGameResults((gameResults) => [...gameResults, currentGameResult])
            setCurrentMissionGameInd((currentMissionGameInd) => currentMissionGameInd + 1)
        }

        if (currentGameStatus.notStarted) {
            if (currentMissionGameInd < missionGames.length) {      // next game
                setCurrentGame(missionGames[currentMissionGameInd])
                setCurrentGameResult(null)
            } else {                                                // all games finished => mission finished
                setMissionStatus({...missionStatus, "notStarted": false, "inProgress": false, "finished": true})

                const compiledMissionResults = gameResults.reduce((prev, curr) => {
                    return {
                        "nb_questions": prev.nb_questions + curr.nb_questions,
                        "nb_correct_answers": prev.nb_correct_answers + curr.nb_correct_answers,
                        "time": prev.time + curr.time
                    }
                }, {"nb_questions": 0, "nb_correct_answers": 0, "time": 0})
                setMissionResult(() => compiledMissionResults)
            }
        }
    }, [currentGameStatus, currentGameResult])

    // useEffect(() => {
    //     console.log("missionStatus", missionStatus)
    //     console.log("currentGameStatus", currentGameStatus)
    // }, [missionStatus])

    useEffect(() => {

        // console.log("missionStatus", missionStatus)
        // console.log("missionResult", missionResult)

        if (missionStatus.finished && missionResult) {

            const score = missionResult.nb_questions && missionResult.nb_questions > 0 ? missionResult.nb_correct_answers / missionResult.nb_questions : null
            // console.log("score", score)
            
            const postMissionRecords_args = {
                "getAccessTokenSilently": getAccessTokenSilently,
                "post_request_body": {
                    "player_id": player.id,
                    "mission_id": mission.id,
                    "nb_games": missionGames.length,
                    "score": score,
                    "time": score && (score > 0.999) ? missionResult.time : null,
                    "datetime": DateTime.now()
                }
            }
            // console.log("postMissionRecords_args", postMissionRecords_args)
            dispatch(postMissionRecord(postMissionRecords_args)).unwrap()
        }
    }, [missionStatus, missionResult])


    return (
        <div className="flex flex-col space-y-10 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-amber-500">

        {missionStatus.notStarted && 
            <MissionStartModal
                missionStatus={missionStatus}
                setMissionStatus={setMissionStatus}
                mission={mission}
                games={missionGames}
                cancelUrl="/missions"
            />
        }

        {missionStatus.inProgress && 
            <Game
                gameStatus={currentGameStatus}
                setGameStatus={setCurrentGameStatus}
                game={currentGame}
                gameResult={currentGameResult}
                setGameResult={setCurrentGameResult}

            />
        }

        {missionStatus.finished &&
            <MissionFinishModal
                missionStatus={missionStatus}
                setMissionStatus={setMissionStatus}
                gameStatus={currentGameStatus}
                setGameStatus={setCurrentGameStatus}
                missionResult={missionResult}
                callbackUrl="/missions"
            />
        }

        </div>
    )
}

