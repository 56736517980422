import React from "react";
import { useNavigate } from 'react-router-dom';

const MissionFinishModal = ( {  missionStatus, setMissionStatus, gameStatus, setGameStatus, missionResult, callbackUrl } ) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(callbackUrl)
    }

    // in the mission-game cycle, the mission finish modal needs to appear when mission is finished and game notStarted (ie after the last game finish results are displayed)

    const score = missionResult.nb_questions && missionResult.nb_questions > 0 ? missionResult.nb_correct_answers / missionResult.nb_questions : null
    // console.log("score", score)


    return (
        <div className="grid gap-4">
            <div className="bg-yellow-300 rounded-xl text-center font-bold p-3">
                {score == 1 ? "SUPER DUPER MISSION 100% TOP GOOD YEAH!!" : (score >= 0.8 ? "OH YEAH! NICE!!!" : "GOOD TO TRY A BIT MORE YEAH!")}
            </div>

            <div className="text-sm text-center">{score > 0.99 ? `TIME ${missionResult.time} sec` : ""} </div>   
            <button
                className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2" 
                type="button"
                onClick={handleClick}
            >
                OK?!
            </button>
        </div>

  );
};

export default MissionFinishModal;

