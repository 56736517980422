import React, { useState, useEffect } from "react";
import { getAllQandas, getQandasStatus, getQandasError } from "./qandasSlice";

import { getPlayerQandaRecords } from "../records/playerQandaRecordsSlice";
import { useSelector, useDispatch } from "react-redux";
import TableR from "../../components/TableR"



const PlayerQandasTable = () => {
    const qandas = useSelector(getAllQandas)
    const status = useSelector(getQandasStatus)
    const errors = useSelector(getQandasError)
    const currentPlayerQandaRecords = useSelector(getPlayerQandaRecords)
    const [dataList, setDataList] = useState(null)

    useEffect(() => {

        const qanda_records = currentPlayerQandaRecords ? currentPlayerQandaRecords : []

        let data = []
        for (let i=0; i<qandas.length; i++) {
            const qanda = qandas[i]  

            const records_mode1 = qanda_records.filter((r) => r.qanda_id === qanda.id && r.game_mode_id === 1)
            const records_mode2 = qanda_records.filter((r) => r.qanda_id === qanda.id && r.game_mode_id === 2)
            const records_mode3 = qanda_records.filter((r) => r.qanda_id === qanda.id && r.game_mode_id === 3)

            const record_bar = (records, mode_label) => {
                if (records.length > 0) {
                    return (
                        <div key={mode_label+i} className="flex flex-row justify-left items-center">
                            <div className="text-xs w-5">{mode_label}</div>
                            {records.map((r,ri) => (
                                <div key={ri} className={`text-xs text-center w-5 hover:bg-white ${r.correct && r.time ? 'bg-green-500' : 'bg-red-500'}`}> {r.correct && r.time ? r.time : "-"} </div>
                            ))}
                        </div>
                    )
                } else {
                    return <div></div>
                }
            }

            if (records_mode1.length > 0 || records_mode2.length > 0 || records_mode3.length > 0) {

                const record_bars = (<div className="flex flex-col gap-0">
                    {record_bar(records_mode1, "m1")}
                    {record_bar(records_mode2, "m2")}
                    {record_bar(records_mode3, "m3")}
                </div>)

                const row = {
                    "id": qanda.id,
                    "question": qanda.question,
                    "answer": qanda.answer,
                    "note": qanda.note,
                    "bars": record_bars // record_bar(records)
                }
                data.push(row)
            }
        }
        setDataList(data)

    }, [currentPlayerQandaRecords])




    return (        
        <TableR headList={["id", "Q", "A", "Note", "records"]} dataList={dataList} dataKeysList={["id", "question", "answer", "note", "bars"]} status={status} />
    )
}

export default PlayerQandasTable



