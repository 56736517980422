import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

const initialState = { 
    modes: [],
    status: 'idle',
    error: null
}




export const fetchGameModes = createAsyncThunk('game_modes/fetchGameModes', async (args) => {
    const requestType = "get"
    const endpoint = "/game_modes/"
    return await payloadCreator(requestType, args, endpoint)
})

export const postGameMode = createAsyncThunk('game_modes/postGameMode', async (args) => {
    const requestType = "post"
    const endpoint = "/game_modes/"
    return await payloadCreator(requestType, args, endpoint)
})

export const deleteGameMode = createAsyncThunk('game_modes/deleteGameMode', async (args) => {
    const requestType = "delete"
    const endpoint = "/game_modes/"
    return await payloadCreator(requestType, args, endpoint)
})




const gameModesSlice = createSlice({
    name: "gameModes",
    initialState,
    reducers: {
        selectGameMode: {
            reducer(state, action){
                state.modes = state.modes.map((c) => (c.id==action.payload)? {...c, "selected": !c.selected } : c )
            },
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchGameModes.pending, (state, action) => {
                console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchGameModes.fulfilled, (state, action) => {
                // payload.modes = list if all mode objects                   
                state.status = 'succeeded'
                console.log("action.payload", action.payload)
                state.modes = action.payload && action.payload.modes ? action.payload.modes.map(mode => {
                    return {...mode, "selected": false};
                }) : []
            })
            .addCase(fetchGameModes.rejected, (state, action) => {
                console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(postGameMode.fulfilled, (state, action) => {
                // payload.mode = mode object created
                console.log("action.payload", action.payload)
                if (action.payload && action.payload.mode) {
                    state.modes.push(action.payload.mode)
                }
            })
            .addCase(deleteGameMode.fulfilled, (state, action) => {
                // payload.mode = mode object deleted
                console.log("action.payload", action.payload)
                if (action.payload && action.payload.mode) {
                    state.modes = state.modes.filter((q) => q.id != action.payload.mode.id)
                }
            })            
    }
})

export const getAllGameModes = (state) => state.gameModes.modes
export const getGameModesStatus = (state) => state.gameModes.status
export const getGameModesError = (state) => state.gameModes.error

export const { selectGameMode } = gameModesSlice.actions

export default gameModesSlice.reducer

