import { REACT_APP_API_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_API_SERVER_URL } from "../config"
import axios from "axios";

const API_AUDIENCE = REACT_APP_API_AUDIENCE;
const AUTH_SCOPE = REACT_APP_AUTH0_SCOPE;
const API_URL = REACT_APP_API_SERVER_URL;


export const payloadCreator = async (requestType, args, endpoint) => {
    try {
        const { getAccessTokenSilently, post_request_body, get_request_params, id } = args

        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: API_AUDIENCE,
                scope: AUTH_SCOPE
            },
        });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }

        if (requestType === "get") {
            // api response to be format:
            // ==> return jsonify({ 'body': thedata}, 200)
            // ==> returned as follows:
            //          - status: 
            //              response.status = 200
            //              response.data[1] = 200
            //              response.data[0].success = true
            //          - thedata: response.data[0].body 
            let response       
            if (get_request_params) {
                response = await axios.get(API_URL + endpoint, { params: get_request_params, headers })  
            } else {
                response = await axios.get(API_URL + endpoint, { headers })       
            }

            if (response.status === 200) {
                // console.log("GET response", response)
                const response_data = await response.data
                // return response_data ? [...response_data] : []
                return response_data
            }
            return null
        }

        if (requestType === "post") {
            // api response to be format:
            // ==> return jsonify({ 'body': thedata}, 200)
            // ==> returned as follows:
            //          - status: 
            //              response.status = 200
            //              response.data[1] = 200
            //              response.data[0].success = true
            //          - thedata: response.data[0].body             
            const response = await axios.post(API_URL + endpoint, post_request_body, { headers })
            if (response.status === 200) {
                // console.log("POST response", response)
                const response_data = await response.data
                return response_data
            }
            return null
        } 

        if (requestType === "delete") {
            // api response to be format:
            // ==> return jsonify({ 'body': thedata}, 200)
            // ==> returned as follows:
            //          - status: 
            //              response.status = 200
            //              response.data[1] = 200
            //              response.data[0].success = true
            //          - thedata: response.data[0].body 

            console.log("uurrll", API_URL + endpoint + id)

            const response = await axios.delete(API_URL + endpoint + id, { headers })    
            if (response.status === 200) {
                // console.log("DELETE response", response)
                const response_data = await response.data
                // return response_data ? [...response_data] : []
                return response_data
            }
            return null
        }    
    
    } catch (err) {
    
        // console.log("err.message", err.message)
        return err.message;
    }
}

