import React from "react";
import { useNavigate } from 'react-router-dom';

const GameStartModal = ( { game, gameStatus, setGameStatus } ) => {

  const navigate = useNavigate();

  const handleCancel = () => {
      navigate("/missions")
  }

  return (
    <div className="grid gap-4">
      <div className="bg-yellow-300 rounded-xl text-center font-bold p-3">GAME {game.name}</div>

      <div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2" 
          type="button"
          onClick={() => setGameStatus((gameStatus) => ({...gameStatus, "notStarted": false, "inProgress": true}))}
        >
          START
        </button>

        <button
          className="bg-gray-300 hover:bg-gray-500 text-black text-sm font-bold py-2 px-4 rounded mx-2" 
          type="button"
          onClick={handleCancel}
        >
          cancel
        </button>
      </div>

    </div>
  );
};

export default GameStartModal;
