import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const MissionStartModal = ( { missionStatus, setMissionStatus, mission, games, cancelUrl } ) => {

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(cancelUrl)
    }

    // useEffect(() => {

    //   if (!mission || !games) {
    //     navigate("/missions")
    //   }
    // }, [mission, games])

  return (

    <>
    {mission && games && 
      <div className="grid gap-4">
        <div className="bg-emerald-400 rounded-xl text-center font-bold p-3">MISSION {mission.name}</div>

        <div className=" text-center grid grid-cols-1 gap-x-2 gap-y-2 m-2">
            {games.map((game, i) => (
              <div key={i} className="bg-yellow-300 rounded-xl p-1">{game.name}</div>
            ))}
        </div>


        <div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2" 
            type="button"
            onClick={() => setMissionStatus({...missionStatus, "notStarted": false, "inProgress": true})}
          >
            START
          </button>

          <button
            className="bg-gray-300 hover:bg-gray-500 text-black text-sm font-bold py-2 px-4 rounded mx-2" 
            type="button"
            onClick={handleCancel}
          >
            cancel
          </button>
        </div>
      </div>        
    }
    </>

  )
}

export default MissionStartModal;
