import React from "react";
import { useNavigate } from 'react-router-dom';

const GameFinishModal = ( { gameStatus, setGameStatus, gameResult, callBackUrl} ) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if (callBackUrl) {      // if callBackUrl specified (training) then redirect
            navigate("/training") 
        } else {                // otherwise update gameStatus
            setGameStatus({...gameStatus, "notStarted": true, "inProgress": false, "finished": false})
        }
        
    }

    const timePerQuestion = gameResult && gameResult.nb_questions > 0 ? Math.round(gameResult.time / gameResult.nb_questions * 100) / 100 : null
    const score = gameResult && (gameResult.nb_questions > 0) ? gameResult.nb_correct_answers / gameResult.nb_questions : null

    return (
        <div className="grid gap-4">
            <div className="bg-yellow-300 rounded-xl text-center font-bold p-3">
                {score == 1 ? "SUPER DUPER 100% YEAH!!" : (score >= 0.8 ? "GOOD YEAH!" : "SOME ROOM FOR PROGRESS YEAH!")}
            </div>

            <div className="text-sm text-center">{score > 0.99 ? `TIME ${gameResult.time} sec` : ""} </div>
            <div className="text-sm text-center">{score > 0.99 ? `(time per question = ${timePerQuestion.toString()} sec)` : ""}</div>          
            <button
                className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2" 
                type="button"
                onClick={handleClick}
            >
                OK?!
            </button>
        </div>
    )
}

export default GameFinishModal;
