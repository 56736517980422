import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

const initialState = { 
    qandas: [],
    status: 'idle',
    error: null
}



export const fetchQandas = createAsyncThunk('qandas/fetchQandas', async (args) => {
    const requestType = "get"
    const endpoint = "/qandas/"
    return await payloadCreator(requestType, args, endpoint)
})

export const postQanda = createAsyncThunk('qandas/postQanda', async (args) => {
    const requestType = "post"
    const endpoint = "/qandas/"
    return await payloadCreator(requestType, args, endpoint)
})

export const deleteQanda = createAsyncThunk('qandas/deleteQanda', async (args) => {
    const requestType = "delete"
    const endpoint = "/qandas/"
    return await payloadCreator(requestType, args, endpoint)
})




const qandasSlice = createSlice({
    name: "qandas", // ensure this name is in 'store'
    initialState,
    reducers: {
        // qandasAdded: {
        //     reducer(state, action){
        //         // push would normally mutate the array BUT not in createSlice. 
        //         // createSlice is using immerjs, which mutates the state underneath
        //         state.qandas.push(action.payload)
        //     },
        //     // prepare callback for the postAdded reducer function, that prepares the 
        //     // payload for the reducer
        //     prepare(question, answer, note, category_id) {
        //         return {
        //             payload: { id: nanoid(), question, answer, note, category_id }
        //         }
        //     }
        // }
        selectQanda: {
            reducer(state, action){
                state.qandas = state.qandas.map((q) => (q.id==action.payload)? {...q, "selected": !q.selected } : q )
            },
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchQandas.pending, (state, action) => {
                console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchQandas.fulfilled, (state, action) => {
                // payload.qandas = list if all qanda objects                 
                state.status = 'succeeded'
                state.qandas = action.payload && action.payload.qandas ? action.payload.qandas.map(q => {
                    // return {...qanda, "selected": false};
                    return {
                        "id": q.id,
                        "question": q.question,
                        "answer": q.answer,
                        "note": q.note,
                        "category_id": q.category_id,
                        "selected": false
                    }
                }) : []
            })
            .addCase(fetchQandas.rejected, (state, action) => {               
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(postQanda.fulfilled, (state, action) => {
                // payload.qanda = qanda object created
                if (action.payload && action.payload.qanda) {
                    state.qandas.push(action.payload.qanda)
                }
            })
            .addCase(deleteQanda.fulfilled, (state, action) => {
                // payload.qanda = qanda object deleted
                if (action.payload && action.payload.qanda) {
                    state.qandas = state.qandas.filter((q) => q.id !== action.payload.qanda.id)
                }
            })
    }
})

export const getAllQandas = (state) => state.qandas.qandas
export const getQandasStatus = (state) => state.qandas.status
export const getQandasError = (state) => state.qandas.error

export const { selectQanda } = qandasSlice.actions

export default qandasSlice.reducer

