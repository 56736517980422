import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

const initialState = { 
    categories: [],
    status: 'idle',
    error: null
}




export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (args) => {
    const requestType = "get"
    const endpoint = "/categories/"
    return await payloadCreator(requestType, args, endpoint)
})

export const postCategory = createAsyncThunk('categories/postCategory', async (args) => {
    const requestType = "post"
    const endpoint = "/categories/"
    return await payloadCreator(requestType, args, endpoint)
})

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (args) => {
    const requestType = "delete"
    const endpoint = "/categories/"
    return await payloadCreator(requestType, args, endpoint)
})




const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        // qandasAdded: {
        //     reducer(state, action){
        //         // push would normally mutate the array BUT not in createSlice. 
        //         // createSlice is using immerjs, which mutates the state underneath
        //         state.qandas.push(action.payload)
        //     },
        //     // prepare callback for the postAdded reducer function, that prepares the 
        //     // payload for the reducer
        //     prepare(question, answer, note, category_id) {
        //         return {
        //             payload: { id: nanoid(), question, answer, note, category_id }
        //         }
        //     }
        // }
        selectCategory: {
            reducer(state, action){
                state.categories = state.categories.map((c) => (c.id==action.payload)? {...c, "selected": !c.selected } : c )
            },
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCategories.pending, (state, action) => {
                console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                // payload.categories = list if all category objects     
                console.log("action.payload", action.payload)              
                state.status = 'succeeded'
                state.categories = action.payload && action.payload.categories ? action.payload.categories.map(category => {
                    return {...category, "selected": false};
                }) : []
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(postCategory.fulfilled, (state, action) => {
                // payload.category = category object created
                console.log("action.payload", action.payload)
                if (action.payload && action.payload.category) {
                    state.categories.push(action.payload.category)
                }
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                // payload.category = category object deleted
                console.log("action.payload", action.payload)
                if (action.payload && action.payload.category) {
                    state.categories = state.categories.filter((q) => q.id != action.payload.category.id)
                }
            })            
    }
})

export const getAllCategories = (state) => state.categories.categories
export const getCategoriesStatus = (state) => state.categories.status
export const getCategoriesError = (state) => state.categories.error

export const { selectCategory } = categoriesSlice.actions

export default categoriesSlice.reducer

