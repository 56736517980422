import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';

import categoriesReducer from '../features/categories/categoriesSlice';
import gameModesReducer from '../features/modes/gameModesSlice';
import qandasReducer from '../features/qandas/qandasSlice';
import gamesReducer from '../features/games/gamesSlice';

import playerReducer from '../features/players/playerSlice';
import playerMissionsReducer from '../features/missions/playerMissionsSlice';

import playerQandaRecordsReducer from '../features/records/playerQandaRecordsSlice';
import playerGameRecordsReducer from '../features/records/playerGameRecordsSlice';
import playerMissionRecordsReducer from '../features/records/playerMissionRecordsSlice';

import allPlayersReducer from '../features/players/allPlayersSlice';
import allMissionsReducer from '../features/missions/allMissionsSlice';

import allQandaRecordsReducer from '../features/records/allQandaRecordsSlice';
import allGameRecordsReducer from '../features/records/allGameRecordsSlice';
import allMissionRecordsReducer from '../features/records/allMissionRecordsSlice';

import gamesStatsReducer from '../features/stats/gamesStatsSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoriesReducer,
    gameModes: gameModesReducer,
    qandas: qandasReducer,
    games: gamesReducer,

    player: playerReducer,
    playerMissions: playerMissionsReducer,
    playerQandaRecords: playerQandaRecordsReducer,
    playerGameRecords: playerGameRecordsReducer,
    playerMissionRecords: playerMissionRecordsReducer,

    allPlayers: allPlayersReducer,
    allMissions: allMissionsReducer,

    allQandaRecords: allQandaRecordsReducer,
    allGameRecords: allGameRecordsReducer,
    allMissionRecords: allMissionRecordsReducer,

    gamesStats: gamesStatsReducer
  },
});

