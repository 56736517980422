import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllQandas, getQandasStatus, getQandasError, selectQanda, deleteQanda } from "./qandasSlice";
import { getAllCategories } from "../categories/categoriesSlice";
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import TableRWD from "../../components/TableRWD"


const QandasTable = () => {
    const [selected_category_id, selected_mode_id, selected_player_id, playerQandaRecords, playerGameRecords, playerMissionRecords] = useOutletContext()

    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const qandas = useSelector(getAllQandas)
    const status = useSelector(getQandasStatus)
    const errors = useSelector(getQandasError)
    const categories = useSelector(getAllCategories)
    const [dataList, setDataList] = useState(null)



    useEffect(() => {
        // const qandas_filter_mode = selected_mode_id > -1 ? qandas.filter((q) => q.mode_id == selected_mode_id) : qandas
        const qandas_filtered = selected_category_id > -1 ? qandas.filter((q) => q.category_id == selected_category_id) : qandas

        const qanda_records = playerQandaRecords ? playerQandaRecords : []
        const qanda_records_filtered = selected_mode_id > -1 ? qanda_records.filter((r) => r.game_mode_id == selected_mode_id) : qanda_records

        let data = []
        for (let i=0; i<qandas_filtered.length; i++) {
            const qanda = qandas_filtered[i]

            const category_name = categories.filter((c) => c.id === qanda.category_id)[0].name ?? ''

            const records_mode1 = qanda_records_filtered.filter((r) => r.qanda_id === qanda.id && r.game_mode_id === 1)
            const records_mode2 = qanda_records_filtered.filter((r) => r.qanda_id === qanda.id && r.game_mode_id === 2)
            const records_mode3 = qanda_records_filtered.filter((r) => r.qanda_id === qanda.id && r.game_mode_id === 3)

            const record_bar = (records, mode_label) => {
                if (records.length > 0) {
                    return (
                        <div key={mode_label+i} className="flex flex-row justify-left items-center">
                            <div className="text-xs w-5">{mode_label}</div>
                            {records.map((r,ri) => (
                                <div key={ri} className={`text-xs text-center w-5 hover:bg-white ${r.correct && r.time ? 'bg-green-500' : 'bg-red-500'}`}> {r.correct && r.time ? r.time : "-"} </div>
                            ))}
                        </div>
                    )
                } else {
                    return <div></div>
                }
            }
    
            const record_bars = (<div className="flex flex-col gap-0">
                {record_bar(records_mode1, "m1")}
                {record_bar(records_mode2, "m2")}
                {record_bar(records_mode3, "m3")}
            </div>)

            const row = {
                "id": qanda.id,
                "question": qanda.question,
                "answer": qanda.answer,
                "note": qanda.note,
                "category_name": category_name,
                "bar": record_bars,   //record_bar(records),
                "selected": qanda.selected
            }
            data.push(row)
        }
        setDataList(data)

    }, [selected_category_id, selected_mode_id, selected_player_id, playerQandaRecords, qandas])


    const handleSelectRow = (e) => {
        console.log("select checkbox, e", e)
        dispatch(selectQanda(e.target.id))
    }

    const handleDeleteRow = (e) => {
        const delete_args = {
            "getAccessTokenSilently": getAccessTokenSilently,
            "id": e.target.id
        }
        dispatch(deleteQanda(delete_args))
    }

    return (
        <TableRWD headList={["Category", "Q", "A", "Note", "id", "player records"]} dataList={dataList} dataKeysList={["category_name", "question", "answer", "note", "id", "bar"]} status={status} handleSelectRow={handleSelectRow} handleDeleteRow={handleDeleteRow}/>
    )
}

export default QandasTable



