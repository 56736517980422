import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllMissions, selectMission, deleteMission, getAllMissionsStatus, getAllMissionsError } from "./allMissionsSlice";
import { getAllPlayers } from "../players/allPlayersSlice";
import { getAllGames } from "../games/gamesSlice";

import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import TableRWD from "../../components/TableRWD"


const MissionsTable = () => { 
    const [selected_category_id, selected_mode_id, selected_player_id, playerQandaRecords, playerGameRecords, playerMissionRecords] = useOutletContext()

    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const missions = useSelector(getAllMissions)
    const status = useSelector(getAllMissionsStatus)
    const errors = useSelector(getAllMissionsError)

    const players = useSelector(getAllPlayers)
    const games = useSelector(getAllGames)

    const [dataList, setDataList] = useState(null)

    useEffect(() => {


        if (missions && playerMissionRecords) {

            const mission_records = playerMissionRecords ? playerMissionRecords : []

            let data = []
            for (let i=0; i<missions.length; i++) {
                const mission = missions[i]

                const mission_games = games.filter( (g) => mission.game_ids.includes(g.id) )
                const player_name = players.find( (p) => p.id == mission.player_id).name
                const records = mission_records.filter((r) => r.mission_id === mission.id)

                const record_bar = (records) => {
                    if (records.length > 0) {
                        return (
                            <div className="flex flex-row justify-left items-center">
                                {records.map((r,ri) => (
                                    <div key={ri} className={`text-xs text-center w-5 hover:bg-white ${r.score==1 ? 'bg-green-500' : 'bg-red-500'}`}> {r.score==1 && r.time ? r.time : "-"} </div>
                                ))}
                            </div>
                        )
                    } else {
                        return <div></div>
                    }
                }

                const row = {
                    "id": mission.id,
                    "name": mission.name,
                    "player_name": player_name,
                    "games": (mission_games.map((g) => g.name)).toString(),
                    "target_score": mission.target_score,
                    "target_times_played": mission.target_times_played,
                    "target_date": new Date(mission.target_date).toDateString(),         
                    "selected": mission.selected,
                    "bar": record_bar(records)
                }
                data.push(row)
            }    

            setDataList(data)
        }

    }, [selected_player_id, games, missions, playerMissionRecords])

    const handleSelectRow = (e) => {
        // console.log("select checkbox, e", e)
        dispatch(selectMission(e.target.id))
    }

    const handleDeleteRow = (e) => {
        const delete_args = {
            "getAccessTokenSilently": getAccessTokenSilently,
            "id": e.target.id
        }
        dispatch(deleteMission(delete_args))
    }

    return (        
        <TableRWD headList={["Name", "player", "games", "target score", "times", "date", "ID", "player records"]} dataList={dataList} dataKeysList={["name", "player_name", "games", "target_score", "target_times_played", "target_date", "id", "bar"]} status={status} handleSelectRow={handleSelectRow} handleDeleteRow={handleDeleteRow}/>
    )
}

export default MissionsTable



