
// import React, { useState } from 'react'; // Importing Modules


const DropdownList = ({items, itemDisplayField, itemId, setItemId}) => { 

    const handleChange = (event) => {
        setItemId(event.target.value);
    }

    return (
        <select className="w-[200px] text-center" value={itemId} onChange={handleChange}>
  
            {items.map((item, i) => (
                <option key={i} value={item.id} >{item[itemDisplayField]}</option> 
            ))}

        </select>
    )
}
export default DropdownList

