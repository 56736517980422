import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { getPlayerMissions } from "../features/missions/playerMissionsSlice"; 
import { getPlayerMissionRecords } from "../features/records/playerMissionRecordsSlice"; 
import { DateTime, toLocaleString } from "luxon"

import { useNavigate } from 'react-router-dom';


export default function PlayerMissions() {

    const navigate = useNavigate();
    const missions = useSelector(getPlayerMissions)
    const missionRecords = useSelector(getPlayerMissionRecords)
    const [missionsInfo, setMissionsInfo] = useState(null)

    useEffect(() => {

        if (missions && missionRecords) {

            const today_dt = DateTime.now()

            const missions_info = missions.map((m) => {

                const records = missionRecords.filter((r) => r.mission_id == m.id)
                const mission_status = records.reduce((p,c) => {
                    return {
                        "times_played": c.times_played > p.times_played ? c.times_played : p.times_played, 
                        "max_score": p.max_score ? (c.score > p.max_score ? c.score : p.max_score) : c.score, 
                        "min_time": p.min_time ? (c.time < p.min_time ? c.time : p.min_time) : c.time
                    }
                }, {"times_played": 0, "max_score": null, "min_time": null})
        
                const meets_target_times_played = mission_status.times_played >= m.target_times_played ? true : false
                const meets_target_score = mission_status.max_score >= m.target_score ? true : false
        
                const target_date = DateTime.fromHTTP(m.target_date)
                const days_left_to_target_date = target_date.diff(today_dt, ["days"]).days  // negative if late

                const status = {
                    "done": meets_target_times_played && meets_target_score,
                    "late": (!meets_target_times_played || !meets_target_score) && days_left_to_target_date < 0
                }
                return {
                    "id": m.id,
                    "name": m.name,
                    "days_left": Math.round(days_left_to_target_date *10) / 10,
                    "times_played": mission_status.times_played,
                    "best_score": Math.round(mission_status.max_score * 100) / 100,
                    "best_time": mission_status.min_time,
                    "status": status
                }
            })
            setMissionsInfo(missions_info)
        }

    }, [missions, missionRecords])


    // useEffect(()=>{
    //     console.log("missionsInfo", missionsInfo)
    // }, [missionsInfo])

    const handleClick = (e) => {
        const missionId = e.currentTarget.id
        navigate("/missions/mission/" + missionId)
    }

    return (
        <div className="bg-yellow-200 h-screen py-4">

            <div className="flex flex-wrap justify-center gap-3">
                {missionsInfo && missionsInfo.map((mission, i) => (
                    <button
                            key={i}
                            className={`text-white font-bold py-2 px-4 rounded-xl mx-2 h-min 
                                ${mission.status.late? "bg-red-500 hover:bg-red-700" 
                                : mission.status.done? "bg-green-500 hover:bg-green-700" 
                                : "bg-blue-500 hover:bg-blue-700"} `} 
                            type="button"
                            id={mission.id}
                            onClick={handleClick}
                        >
                            <div className="flex flex-col">
                                <h2 className="font-bold">{mission.name}</h2>
                                <span className="text-xs">{mission.days_left >= 0 ? mission.days_left + " days left" : "overdue by " + -1 * mission.days_left + " days"}</span>
                                <span className="text-xs">{mission.times_played > 0 ? "played x " + mission.times_played : "not played yet"}</span>
                                <span className="text-xs">{mission.times_played > 0 ? "best score " + mission.best_score : "-"}</span>
                                <span className="text-xs">{mission.times_played > 0 && mission.best_time ? "best time " + mission.best_time +"s" : "-"}</span>
                            </div>
                    </button>        
                ))}
            </div>

        </div>

    )
}