import { createAsyncThunk, createSlice, nanoid, current } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// playerMissionRecords
// - GET mission records for the current player
// - POST records at completion of missions


const initialState = {   
    records: [],
    status: 'idle',
    error: null
}

export const fetchPlayerMissionRecords = createAsyncThunk('records/fetchPlayerMissionRecords', async (args) => {
    const requestType = "get"
    const endpoint = "/mission_records/player"
    return await payloadCreator(requestType, args, endpoint)
})

export const postMissionRecord = createAsyncThunk('records/postMissionRecord', async (args) => {
    const requestType = "post"
    const endpoint = "/mission_records/"
    return await payloadCreator(requestType, args, endpoint)
})



const playerMissionRecordsSlice = createSlice({
name: "playerMissionRecords", // ensure this name is in 'store'
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPlayerMissionRecords.fulfilled, (state, action) => {
                // payload.records = list if all record objects
                // console.log("fetchPlayerMissionRecords, action.payload", action.payload)                
                state.status = 'succeeded'
                state.records = action.payload && action.payload.records ? action.payload.records.map(record => {
                    return {...record};
                }) : []
            })        
            .addCase(postMissionRecord.fulfilled, (state, action) => {
                // payload.record = record object created
                // console.log("postMissionRecord, action.payload", action.payload)

                if (action.payload && action.payload.record_added) {
                    const existing_records = state.records
                    // console.log("postMissionRecord, existing_records", existing_records)

                    const new_record_added = action.payload.record_added
                    // console.log("postMissionRecord, new_record_added", new_record_added)

                    const record_dropped = action.payload.record_dropped? action.payload.record_dropped : null
                    // console.log("postMissionRecord, record_dropped", record_dropped)

                    const record_dropped_id = record_dropped ? [record_dropped.id] : []
                    // console.log("postMissionRecord, record_dropped_id", record_dropped_id)

                    const new_records = [...existing_records, new_record_added]
                    // console.log("postMissionRecord, new_records", new_records)

                    const new_records_trimmed = new_records.filter((r) => !record_dropped_id.includes(r.id) )
                    // console.log("postMissionRecord, new_records_trimmed", new_records_trimmed)

                    state.records = new_records_trimmed
                }
            })
    }
})


export const getPlayerMissionRecords = (state) => state.playerMissionRecords.records
export const getPlayerMissionRecordsStatus = (state) => state.playerMissionRecords.status
export const getPlayerMissionRecordsError = (state) => state.playerMissionRecords.error

// export const { loadCurrentPlayerQandaRecords } = qandaRecordsSlice.actions

export default playerMissionRecordsSlice.reducer

