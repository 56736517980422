import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { payloadCreator } from "../../utils/utilsAPI"

// import { REACT_APP_API_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_API_SERVER_URL } from "../../config"
// import axios from "axios";

// const API_AUDIENCE = REACT_APP_API_AUDIENCE;
// const AUTH_SCOPE = REACT_APP_AUTH0_SCOPE;
// const API_URL = REACT_APP_API_SERVER_URL;

const initialState = { 
    missions: null,
    status: 'idle',
    error: null
}

export const fetchAllMissions = createAsyncThunk('missions/fetchAllMissions', async (args) => {
    const requestType = "get"
    const endpoint = "/missions/all"
    return await payloadCreator(requestType, args, endpoint)
})

export const postMission = createAsyncThunk('missions/postMission', async (args) => {
    const requestType = "post"
    const endpoint = "/missions/"
    return await payloadCreator(requestType, args, endpoint)
})

export const deleteMission = createAsyncThunk('missions/deleteMission', async (args) => {
    const requestType = "delete"
    const endpoint = "/missions/"
    return await payloadCreator(requestType, args, endpoint)
})





const allMissionsSlice = createSlice({
    name: "allMissions", // ensure this name is in 'store'
    initialState,
    reducers: {
        selectMission: {
            reducer(state, action){
                state.missions = state.missions.map((m) => (m.id==action.payload)? {...m, "selected": !m.selected } : m )
            },
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllMissions.pending, (state, action) => {
                // console.log('loading')
                state.status = 'loading'
            })
            .addCase(fetchAllMissions.fulfilled, (state, action) => {
                // payload.games = list if all game objects                     
                // console.log("state.missions", state.missions)
                state.status = 'succeeded'
                state.missions = action.payload && action.payload.missions ? action.payload.missions.map(m => {
                    // return {...game, "selected": false};
                    return {
                        "id": m.id,
                        "name": m.name,
                        "player_id": m.player_id,
                        "game_ids": m.game_ids,
                        "target_score": m.target_score,
                        "target_times_played": m.target_times_played,
                        "target_date": m.target_date,
                        "selected": false  // temp field
                    }
                }) : []
            })
            .addCase(fetchAllMissions.rejected, (state, action) => {
                // console.log('failed')
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(postMission.fulfilled, (state, action) => {   //TODO: case of POST when existing state is null
                // payload.game = game object created
                // console.log("action.payload", action.payload)
                // console.log("state.missions", state.missions)
                if (action.payload && action.payload.mission) {
                    const new_mission = action.payload.mission

                    if (state.missions) {
                        state.missions.push({
                            "id": new_mission.id,
                            "name": new_mission.name,
                            "player_id": new_mission.player_id,
                            "game_ids": new_mission.game_ids,
                            "target_score": new_mission.target_score,
                            "target_times_played": new_mission.target_times_played,
                            "target_date": new_mission.target_date,
                            "selected": false  // temp field
                        })
                    } else {  
                        state.missions = [{
                            "id": new_mission.id,
                            "name": new_mission.name,
                            "player_id": new_mission.player_id,
                            "game_ids": new_mission.game_ids,
                            "target_score": new_mission.target_score,
                            "target_times_played": new_mission.target_times_played,
                            "target_date": new_mission.target_date,
                            "selected": false  // temp field
                        }]
                    }
                }
            })
            .addCase(deleteMission.fulfilled, (state, action) => {
                // payload.game = game object deleted                
                // console.log("action.payload", action.payload)
                if (action.payload && action.payload.mission) {
                    state.missions = state.missions.filter((q) => q.id !== action.payload.mission.id)
                }
            })
    }
})

export const getAllMissions = (state) => state.allMissions.missions
export const getAllMissionsStatus = (state) => state.allMissions.status
export const getAllMissionsError = (state) => state.allMissions.error

export const { selectMission } = allMissionsSlice.actions

export default allMissionsSlice.reducer

